import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { ref, set, push, onValue, off,get } from "firebase/database";
import { database } from "./firebase";
import { useSelector } from "react-redux";
import { selectToken } from "slices/auth/login/reducer";
import decodeJwt, { JwtPayload } from "helpers/jwt-token-access/jwtdecode";

interface EventType {
  id: string;
  title: string;
  start: string;
  end?: string;
  date?: string;
}

const EventCalendar: React.FC = () => {
  const [events, setEvents] = useState<EventType[]>([]);
  const decodedToken: JwtPayload | null = decodeJwt();
  const userEmail = decodedToken ? decodedToken.email.replace(".", "_") : "";


  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsRef = ref(database, `events/${userEmail}`);
        const snapshot = await get(eventsRef);
        const data = snapshot.val();
  
        if (data) {
          const loadedEvents: EventType[] = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          console.log(loadedEvents);
          setEvents(loadedEvents);
        } else {
          setEvents([]);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
  
    fetchEvents();
  }, [userEmail]); // ✅ Fetch only once when `userEmail` changes
  
  const handleDateSelect = (selectInfo: any) => {
    const title = prompt("Enter event title:");
    if (title) {
      const newEvent: Omit<EventType, "id"> = {
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr || selectInfo.startStr,
        date: selectInfo.endStr || selectInfo.startStr,
      };

      const eventRef = push(ref(database, `events/${userEmail}`));
      set(eventRef, newEvent).then(() => {
        setEvents((prev) => [...prev, { id: eventRef.key!, ...newEvent }]);
      });
    }
  };


  const handleEventClick = (clickInfo: any) => {
    if (window.confirm(`Do you want to delete "${clickInfo.event.title}"?`)) {
      const eventId = clickInfo.event.id;
      const eventRef = ref(database, `events/${userEmail}/${eventId}`);
  
      set(eventRef, null) // ✅ Remove event from Firebase
        .then(() => {
          setEvents(prevEvents => prevEvents.filter(event => event.id !== eventId)); // ✅ Remove from UI
        })
        .catch(error => {
          alert("Error deleting event: " + error.message);
        });
    }
  };
  

  return (
    <div style={{
      marginBottom:20
    }}>
    <FullCalendar
  key={events.length}
  plugins={[dayGridPlugin, interactionPlugin]}
  initialView="dayGridMonth"
  selectable={true}
  events={events}
  eventClick={handleEventClick} // ✅ Add this
  select={handleDateSelect}
  eventContent={(eventInfo) => (
    <div style={{ 
      fontWeight: "bold", 
      fontSize: "14px", 
      color: "white", 
      backgroundColor: "#007bff", 
      padding: "2px", 
      borderRadius: "4px" 
    }}>
      {eventInfo.event.title}
    </div>
  )}
      />
    </div>
  );
};

export default EventCalendar;