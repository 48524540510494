import React, { useState, useEffect } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Col, Container, Row } from "react-bootstrap";

// Import Components
import Widgets from "./widgets";
import PropertiesType from "./propertiesType";
import RevenueOverview from "./revenueOverview";
import Property from "./property";
import Feedback from "./feedback";
import PopularProperty from "./popularProperty";
import PropertyCharts from "./propertyCharts";
import RecentActivity from "./recentActivity";
import AgentList from "./agentList";
import decodeJwt, {
  JwtPayload,
} from "../../../helpers/jwt-token-access/jwtdecode";
import EventCalendar from "./calender";

const RealEstate = () => {
  const [decodedJwt, setDecodedJwt] = useState({});
  useEffect(() => {
    // Call the decodeJwt function to get the decoded values
    const decodedToken: JwtPayload | null = decodeJwt();

    if (decodedToken) {
      // Access the decoded values
      setDecodedJwt(decodedToken.userType);
    } else {
      console.error("Decoded token is null");
    }
  }, []);
  
  if (decodedJwt === "homeowner") {
    document.title = "The Relibuild Dashboard";
  } else {
    document.title = "The Tradesmens Dashboard";
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={decodedJwt === "homeowner" ? "Relibuild" : "Tradesmens"}
            pageTitle="Dashboard"
          />
          <Widgets />
          <EventCalendar />
          {decodedJwt === "homeowner" ? (
            <Row>
              <Property />
              <RevenueOverview />
            </Row>
          ) : (
            ""
          )}
          {decodedJwt === "admin" || decodedJwt === "contractor" ? (
            <Row>
              <PropertiesType />
              <RevenueOverview />
            </Row>
          ) : (
            ""
          )}
          {decodedJwt === "homeowner" ? (
            <Row>
              <Feedback />
              <AgentList />
              <PropertyCharts />
              <RecentActivity />
            </Row>
          ) : (
            ""
          )}
          {decodedJwt === "admin" ? (
            <Row>
              <Feedback />
              <Property />
            </Row>
          ) : (
            ""
          )}
          {decodedJwt === "admin" ? (
            <Row>
              <Col xxl={12}>
                <Row>
                  <PropertyCharts />
                  <RecentActivity />
                  <AgentList />
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RealEstate;
