import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
//import images
import avatar1 from "assets/images/users/32/avatar-1.jpg";
import { createSelector } from "reselect";
import { getUserData } from "../slices/thunk";
import { useTranslation } from "react-i18next";

interface JwtPayload {
  userType: string;
}

const ProfileDropdown = () => {
  const dispatch: any = useDispatch();

  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error("Token is null");
  }
  const [userName, setUserName] = useState<any>("Admin");
  const [userType, setUserType] = useState<any>("Admin");
  const [profilePic, setProfilePic] = useState<any>(null);

  const selectUser = createSelector(
    (state: any) => state.Profile.user,
    (user) => user
  );

  const user = useSelector(selectUser);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const authUser: any = localStorage.getItem("authUser");
      const obj = JSON.parse(authUser);
      setUserType(decoded.userType);
    }
  }, [userName, user]);
  useEffect(() => {
    dispatch(getUserData()).then((resp: any) => {
      setUserName(resp && resp[0].user_first_name);
      setProfilePic(resp && resp[0].ua_profile_pic);
    });
  });
  const {t} = useTranslation()
  return (
    <React.Fragment>
    <Dropdown className="ms-sm-3 header-item topbar-user">
        <Dropdown.Toggle
            type="button"
            className="btn bg-transparent border-0 arrow-none"
            id="page-header-user-dropdown"
        >
            <span className="d-flex align-items-center">
                <img
                    className="rounded-circle header-profile-user"
                    src={profilePic != null ? profilePic : avatar1}
                    alt={t("Header Avatar")}
                />
                <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                        {userName}
                    </span>
                    <span className="d-none d-xl-block ms-1 fs-sm text-muted user-name-sub-text">
                        {userType}
                    </span>
                </span>
            </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
            <h6 className="dropdown-header">{t("Welcome")} {userName}!</h6>
            <Dropdown.Item href={process.env.PUBLIC_URL + "#"}>
                <i className="mdi mdi-message-text-outline text-muted fs-lg align-middle me-1"></i>{" "}
                <span className="align-middle">{t("Messages")}</span>
            </Dropdown.Item>
            <Dropdown.Item href={process.env.PUBLIC_URL + "#"}>
                <i className="mdi mdi-lifebuoy text-muted fs-lg align-middle me-1"></i>{" "}
                <span className="align-middle">{t("Help")}</span>
            </Dropdown.Item>
            <div className="dropdown-divider"></div>
            <Dropdown.Item href={process.env.PUBLIC_URL + "/pages-profile-settings"}>
                <span className="badge bg-soft-success text-success mt-1 float-end">
                    {t("New")}
                </span>
                <i className="mdi mdi-cog-outline text-muted fs-lg align-middle me-1"></i>{" "}
                <span className="align-middle">{t("Settings")}</span>
            </Dropdown.Item>
            <Dropdown.Item href={process.env.PUBLIC_URL + "/logout"}>
                <i className="mdi mdi-logout text-muted fs-lg align-middle me-1"></i>{" "}
                <span className="align-middle" data-key="t-logout">
                    {t("Logout")}
                </span>
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
</React.Fragment>

  );
};

export default ProfileDropdown;
