import React from 'react';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import avatar2 from "assets/images/users/32/avatar-2.jpg";
import avatar8 from "assets/images/users/32/avatar-8.jpg";
import { useTranslation } from 'react-i18next';

const NotificationDropdown = () => {
const {t} = useTranslation()
    return (
 <React.Fragment>
    <Dropdown className="topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
        <Dropdown.Toggle id="notification" type="button" className="btn btn-icon btn-topbar btn-ghost-dark rounded-circle arrow-none">
            <i className='bi bi-bell fs-2xl'></i>
            <span className="position-absolute topbar-badge fs-3xs translate-middle badge rounded-pill bg-danger">
                <span className="notification-badge">4</span>
                <span className="visually-hidden">{t("unread messages")}</span>
            </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">

            <div className="dropdown-head rounded-top">
                <div className="p-3 border-bottom border-bottom-dashed">
                    <Row className="align-items-center">
                        <Col>
                            <h6 className="mb-0 fs-lg fw-semibold">
                                {t("Notifications")} <span className="badge bg-danger-subtle text-danger fs-sm notification-badge">1</span>
                            </h6>
                            <p className="fs-md text-muted mt-1 mb-0">
                                {t("You have")} <span className="fw-semibold notification-unread">1</span> {t("unread messages")}
                            </p>
                        </Col>
                        <Dropdown className="col-auto">
                            <Dropdown.Toggle as="a" href={process.env.PUBLIC_URL + "/"} data-bs-toggle="dropdown" className="link-secondary fs-md bg-transparent border-0 arrow-none">
                                <i className="bi bi-three-dots-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <li><Dropdown.Item href="#!">{t("All Clear")}</Dropdown.Item></li>
                                <li><Dropdown.Item href="#!">{t("Mark all as read")}</Dropdown.Item></li>
                                <li><Dropdown.Item href="#!">{t("Archive All")}</Dropdown.Item></li>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Row>
                </div>
            </div>

            <div className="py-2 ps-2" id="notificationItemsTabContent">
                <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                    <h6 className="text-overflow text-muted fs-sm my-2 text-uppercase notification-title">{t("New")}</h6>
                    <div className="text-reset notification-item d-block dropdown-item position-relative unread-message">
                        <div className="d-flex">
                            <div className="avatar-xs me-3 flex-shrink-0">
                                <span className="avatar-title bg-info-subtle text-info rounded-circle fs-lg">
                                    <i className="bx bx-badge-check"></i>
                                </span>
                            </div>
                            <div className="flex-grow-1">
                                <Link to="#!" className="stretched-link">
                                    <h6 className="mt-0 fs-md mb-2 lh-base">
                                        {t("Your")} <b>{t("Request has been accepted")}</b>
                                    </h6>
                                </Link>
                                <p className="mb-0 fs-2xs fw-medium text-uppercase text-muted">
                                    <span><i className="mdi mdi-clock-outline"></i> {t("Just 30 sec ago")}</span>
                                </p>
                            </div>
                            <div className="px-2 fs-md">
                                <div className="form-check notification-check">
                                    <input className="form-check-input" type="checkbox" value="" id="all-notification-check01" />
                                    <label className="form-check-label" htmlFor="all-notification-check01"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
                <div className="notification-actions" id="notification-actions">
                    <div className="d-flex text-muted justify-content-center align-items-center">
                        {t("Select")} <div id="select-content" className="text-body fw-semibold px-1">0</div> {t("Result")} 
                        <Button variant='link-danger' className="btn-link p-0 ms-2" data-bs-toggle="modal" data-bs-target="#removeNotificationModal">
                            {t("Remove")}
                        </Button>
                    </div>
                </div>
            </div>
        </Dropdown.Menu>
    </Dropdown>
</React.Fragment>

    );
}

export default NotificationDropdown;