import React, { useEffect, useRef, useState } from "react";
import { TourProvider, useTour } from "@reactour/tour";
import {
  getContractsById,
  getSignedContracts,
  searchInspector,
  getHomeOwnerById,
  assignContract,
  getHomeownerContractsById,
  getInspectorContractsById,
  getSubcontractorContractsById,
} from "../../../../slices/thunk";
import {
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
  Spinner,
  Button,
  Table,
  Modal,
  Alert,
} from "react-bootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";


interface ContractListProps {}

interface JwtPayload {
  userType: string;
}

const ContractList: React.FC<ContractListProps> = () => {
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  const { t } = useTranslation(); // ✅ React knows to update


  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error("Token is null");
  }
  const contracts = useSelector((state: any) => state.Contract.contracts);

  const contractors = useSelector(
    (state: any) => state.Proposal.searchHomeowners
  );

  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [showHomeownerForm, setShowHomeownerForm] = useState<boolean>(false);
  const [homeOwnerId, setHomeOwnerId] = useState<any>("");
  const [newHomeowner, setNewHomeowner] = useState<any>([]);
  const [inspectorData, setInspectorData] = useState<any>(null);
  const [projectIdState, setProjectId] = useState<any>("");
  const [modal_assign_inspector, setModal_assign_inspector] = useState(false);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  let project: any = null;
  project = useSelector((state: any) => state.Contract.project);

  useEffect(() => {
    document.title = "Tradesmen Admin";
  }, []);

  function tog_assign_inspector(pId: any) {
    setProjectId(pId);
    setModal_assign_inspector(!modal_assign_inspector);
  }

  useEffect(() => {
    // Function to handle the click event
    const handleClickOutside = (event: MouseEvent) => {
      // Get the modal elements
      const backdropElement = document.getElementById("staticBackdrop");

      // Check if the click is outside the modal
      if (backdropElement && !backdropElement.contains(event.target as Node)) {
        // Close the modals if they are open
        if (modal_assign_inspector) {
          setModal_assign_inspector(false);
        }
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal_assign_inspector]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (decoded.userType === "admin") {
          await dispatch(getSignedContracts());
        } else if (decoded.userType === "contractor") {
          await dispatch(getContractsById());
        } else if (decoded.userType === "homeowner") {
          await dispatch(getHomeownerContractsById());
        } else if (decoded.userType === "inspector") {
          await dispatch(getInspectorContractsById());
        } else if (decoded.userType === "subcontractor") {
          await dispatch(getSubcontractorContractsById());
        }
      } catch (error) {
        console.error("Error fetching contracts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, decoded.userType]);

  const handleSelectedValue = async (e: any) => {
    setLoading(true);
    setHomeOwnerId(e);
    try {
      await dispatch(getHomeOwnerById(e)).then((resp: any) => {
        setInspectorData(resp[0]);
      });
      setShowHomeownerForm(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching homeowner data:", error);
    }
  };

  function searchHomeowners(e: any) {
    dispatch(searchInspector(e));
    // setShowHomeownerForm(true)
  }

  useEffect(() => {
    if (contractors.length) {
      const newData = contractors.map((item: any) => {
        return { label: item.user_email, value: item.id };
      });
      setNewHomeowner(newData);
    }
  }, [contractors]);

  function handleAssignContract() {
    setLoading(true);
    let projectId = projectIdState;
    let inspectorId = homeOwnerId;
    dispatch(assignContract(projectId, inspectorId));
    setModal_assign_inspector(false);
    setNotification({
      status: true,
      message: "Inspector Assigned Successfully",
      variant: "success",
    });

    setLoading(false);
  }
  const handleAlertClick = () => {
    setNotification(null);
  };

  function convertToUSFormat(isoDate: any) {
    const date = new Date(isoDate);
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }
  const { setIsOpen,setSteps } = useTour();

  return (
    <React.Fragment>
  
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {decoded.userType === "contractor" ||
          decoded.userType === "testContractor" ? (
            <div style={{
              display:'flex',
              justifyContent:'space-between'
            }}>
   <Link onClick={()=>{
              // localStorage.setItem("is_create_new_contract_explored","yes")
              // setIsOpen(false)
            }} to="/create-contract">
              <Button  className="primary  step-1 mb-4">
                <i className="bx bx-spreadsheet align-center me-1"></i> {t("Create New Contract") }

              </Button>
            </Link>

            <Button style={{
              backgroundColor:'#F7F7F7',
              color:'black',
              border:'1px solid black'
            }} onClick={()=>{
              if(setSteps){
                setIsOpen(false)

                setIsOpen(true)
                setSteps([
                  {
                    selector: ".step-1",
                    content: t("Click here to create a new contract."),
                  },
                  {
                    selector: ".step-2",
                    content: t("View your list of created contracts here."),
                  },
                ]);
                
              }
            }} className="  mb-4">
  <i className="bx bx-book-open align-center me-1"></i> {t("Start Guide")}
</Button>

            </div>
         
          ) : (
            ""
          )}

<Card>
  <Card.Body>
    <Table className="table-striped  table-nowrap align-middle mb-0">
      <thead className="step-2">
        <tr>
          <th >{t("project_id")}</th>
          <th>{t("project_name")}</th>
          <th>{t("status")}</th>
          <th>{t("home_owner")}</th>
          <th>{t("project_created_date")}</th>
          <th>{t("escrow")}</th>
          <th>{t("progress")}</th>
          {decoded.userType === "admin" ? <th>{t("assign_inspector")}</th> : ""}
        </tr>
      </thead>
      <tbody>
        {contracts.length > 0 &&
          contracts.map((item: any) => (
            <tr key={item.p_id} style={{ cursor: "pointer" }}>
              <td
                onClick={() =>
                  (window.location.href = `/create-contract?Project-Details?id=${item.p_id}`)
                }
              >
                {item.p_id}
              </td>
              <td
                onClick={() =>
                  (window.location.href = `/create-contract?Project-Details?id=${item.p_id}`)
                }
              >
                {item.p_name}
              </td>
              <td
                onClick={() =>
                  (window.location.href = `/create-contract?Project-Details?id=${item.p_id}`)
                }
              >
                <span
                  className={`${
                    item.p_status === "LIVE" ? "badge bg-success" : "badge bg-danger"
                  }`}
                >
                  {t(item.p_status === "LIVE" ? "live" : "inactive")}
                </span>
              </td>
              <td
                onClick={() =>
                  (window.location.href = `/create-contract?Project-Details?id=${item.p_id}`)
                }
              >
                {item.p_home_owner_id}
              </td>
              <td
                onClick={() =>
                  (window.location.href = `/create-contract?Project-Details?id=${item.p_id}`)
                }
              >
                {convertToUSFormat(item.p_created_at)}
              </td>
              <td
                onClick={() =>
                  (window.location.href = `/create-contract?Project-Details?id=${item.p_id}`)
                }
              >
                <span
                  className={
                    item.p_escrow == 0 ? "badge bg-danger" : "badge bg-success"
                  }
                >
                  {t(item.p_escrow == 0 ? "no" : "yes")}
                </span>
              </td>
              <td></td>
              {decoded.userType === "admin" ? (
                <td>
                  <Button
                    variant="danger"
                    onClick={() => tog_assign_inspector(item.p_id)}
                  >
                    {t("assign_inspector")}
                  </Button>
                </td>
              ) : (
                ""
              )}
            </tr>
          ))}
      </tbody>
    </Table>
  </Card.Body>
</Card>

        </>
      )}
<Modal
  size="lg"
  show={modal_assign_inspector}
  onHide={() => {
    tog_assign_inspector(null);
  }}
  backdrop={"static"}
  id="staticBackdrop"
  centered
  data-bs-focus="false"
>
  <Modal.Header
    className="modal-title fw-bold"
    id="staticBackdropLabel"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    {t("Assign Inspector")}
  </Modal.Header>
  <Modal.Body className="p-5" style={{ background: "rgb(249, 250, 251)" }}>
    <Col lg={12}>
      <div>
        <Form.Label htmlFor="searchSubContractor">
          {t("Search By Email")}
        </Form.Label>
        <Select
          options={newHomeowner}
          onInputChange={(e: any) => searchHomeowners(e)}
          onChange={(selectedOption: any) => {
            if (selectedOption && selectedOption.value) {
              handleSelectedValue(selectedOption.value);
            }
          }}
        />
      </div>
    </Col>
    {showHomeownerForm ? (
      <React.Fragment>
        <Row className="g-3 align-items-center mb-3">
          <Col lg={6}>
            <div>
              <Form.Label htmlFor="firstName">
                {t("First name")}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="firstName"
                name="firstName"
                placeholder={t("Enter First Name")}
                value={inspectorData ? inspectorData.user_first_name : ""}
                required
                disabled
              />
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <Form.Label htmlFor="lastName">
                {t("Last name")}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="lastName"
                name="lastName"
                placeholder={t("Enter Last Name")}
                value={inspectorData ? inspectorData.user_last_name : ""}
                required
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row className="g-3 align-items-center">
          <Col lg={6}>
            <div>
              <Form.Label htmlFor="homeOwnerEmail">
                {t("Email address")}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="homeOwnerEmail"
                name="homeOwnerEmail"
                placeholder={t("Enter Email")}
                value={inspectorData ? inspectorData.user_email : ""}
                required
                disabled
              />
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <Form.Label htmlFor="homeOwnerPhone">
                {t("Phone")}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="homeOwnerPhone"
                name="homeOwnerPhone"
                placeholder={t("Enter Homeowner Phone")}
                value={inspectorData ? inspectorData.user_phone : ""}
                required
                disabled
              />
            </div>
          </Col>
        </Row>
      </React.Fragment>
    ) : (
      ""
    )}
    <div className="d-flex mt-2 justify-content-between">
      <Button variant="success" onClick={handleAssignContract}>
        {t("Submit")}
      </Button>
    </div>
  </Modal.Body>
</Modal>
  

    </React.Fragment>
  );
};

export default ContractList;
