import React, { useEffect, useState } from "react";
import Breadcrumb from "Common/BreadCrumb";
import { getContractsById } from "../../../slices/thunk";
import { Col, Container, Row, Spinner, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ContractList from "./contract-list";
import { jwtDecode } from "jwt-decode";
import Tutorial from "./Tutorial";
import {
  acceptContractTerms,
  getSubscribedUser,
  handleStripePackage,
  cancelSubscription,
} from "../../../slices/thunk";
import { useTranslation } from "react-i18next";


interface CreateProjectProps {}
interface JwtPayload {
  userType: string;
}

const CreateProject: React.FC<CreateProjectProps> = () => {
  const dispatch: any = useDispatch();
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  const { t } = useTranslation(); // ✅ React knows to update


  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error("Token is null");
  }
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Tradesmen Admin";
  }, []);

  const [activeArrowTab, setactiveArrowTab] = useState(1);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const handleCreateAndSetTab = () => {
    setactiveArrowTab(2); // Set to Milestones or any other tab index
  };
  // useEffect(() => {
  //   dispatch(getContractsById())
  // },[])

  useEffect(() => {
    setLoading(true);
    dispatch(getSubscribedUser()).then((resp: any) => {
      if (decoded.userType === "contractor") {
        if (resp && resp.subscription.cpp_is_active === "ACTIVE") {
          setTermsAccepted(true);
        } else {
          setTermsAccepted(false);
        }
      } else {
        setTermsAccepted(true);
      }
      setLoading(false);
    });
  }, [dispatch]);

  const userSubscribed = useSelector(
    (state: any) => state.UserProfile.subscribedData
  );
  const handleSubmitStatus = () => {
    //setTermsAccepted(true);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {termsAccepted &&
            (decoded.userType === "contractor" ||
              decoded.userType === "testContractor") &&
            (userSubscribed && userSubscribed.remainingDays === 0 ? (
              <Alert variant="success">
                <i className="bx bx-spreadsheet align-baseline me-1"></i>
             {t("Your Subscription is live. Thank you for choosing The Tradesmens Collective.")}
               
                <Link to="/contract-tutorial-and-plans">
                  {t("Know More or Cancel Plan")}
                </Link>
              </Alert>
            ) : (
              <Alert variant="danger">
                <i className="bx bx-spreadsheet align-baseline me-1"></i>
                {t("You have a complimentary")} <b> {t("30-day trial period")}</b> {t("to experience this contract service at no cost. Only")}
                <b>
                  {userSubscribed && userSubscribed.remainingDays} days left
                </b>
                .{" "}
                <Link to="/contract-tutorial-and-plans">
                 {t("Know More or Cancel Plan")} 
                </Link>
              </Alert>
            ))}

          <Breadcrumb title= {'Contracts'} pageTitle="Contract" />

          <Row>
            <Col xl={12}>
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "200px" }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : termsAccepted ? (
                <ContractList />
              ) : (
                <Tutorial handleStatus={handleSubmitStatus} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateProject;
