//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import axios from "axios";

import { jwtDecode } from "jwt-decode";

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';

import apiUrl from "slices/apiEndpoint";

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
  try {
    const response = await axios.post(`${apiUrl}/login/login`, {
      email: user.email
    });
    const data = response; // Access the response data here
    localStorage.setItem('userEmail', user.email)

    ////console.log(data); // Do something with the data
    return data
  }
  catch (error) {
    dispatch(apiError("User Not Found"));
  }
};

export const otpVerification = (user: any, history: any) => async (dispatch: any) => {
  try {
    const response = await axios.post(`${apiUrl}/login/verify-otp`, {
      email:localStorage.getItem('userEmail'),
      otp: user.otp
    });
    let data = response.data;
    if (data) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("authUser", JSON.stringify(data.token));
      
      if (getDecodeUserType() === "admin" || getDecodeUserType() === "homeowner") {
       
        dispatch(loginSuccess(data));
        history("/dashboard");
      }
      else {
        dispatch(loginSuccess(data));
        history("/pages-profile-settings");
      }
    }
  }
   catch (error) {
    dispatch(apiError('One time password is expired or invalid'));
  }
};



export const logoutUser = () => async (dispatch: any) => {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    localStorage.removeItem("userEmail")
      dispatch(logoutUserSuccess(true));

  } catch (error) {
    dispatch(apiError(error));
  }
};

export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
  try {
    let response;

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      response = fireBaseBackend.socialLoginUser(type);
    }
    //  else {
    //   response = postSocialLogin(data);
    // }

    const socialdata = await response;
    if (socialdata) {
      localStorage.setItem("authUser", JSON.stringify(socialdata));
      dispatch(loginSuccess(socialdata));
      history('/dashboard');
    }

  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const isTokenExpire = () => async (dispatch: any) => {
  try {
    const response = await axios.post(`${apiUrl}/login/check-token`, {
      token:localStorage.getItem('token')
    });
    let data = response.data;
    if (!data) {
      localStorage.removeItem("authUser");
    localStorage.removeItem("token");
      localStorage.removeItem("userEmail")
      window.location.reload()
      dispatch(logoutUserSuccess(true));
    }
   }
  catch (error) {
    dispatch(apiError(error));
  }
};


function getDecodeUserType() {
   const token = localStorage.getItem('authUser')
    let decoded: any = "";
    if (token !== null) {
         decoded = jwtDecode(token);
      } else {
        console.error('Token is null');
    }
  return decoded.userType
}
 