import React, { useEffect, useState } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import axios from "axios";

import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Dropdown,
  ProgressBar,
  Row,
  ListGroup,
  Image,
} from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import MyLeads from "./leads/myLeads";
import { useSelector, useDispatch } from "react-redux";
import { getLeads, getAplliedLeads } from "../../slices/thunk";
import apiUrl from "slices/apiEndpoint";
import { useTranslation } from "react-i18next";

interface JwtPayload {
  userType: string;
}

const Projects = () => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(); // ✅ React knows to update


  useEffect(() => {
    dispatch(getLeads());
  }, []);
  useEffect(() => {
    dispatch(getAplliedLeads());
  });

  document.title =t('Leads Pro')

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={'Leads Pro'} pageTitle="Leads" />
          <Row>
            {/* <div className="hstack gap-2 justify-content-end d-print-none mb-4">
              <Button
                variant="primary"
                onClick={() => handleEscrow("true,false")}
              >
                All
              </Button>
              <Button variant="primary" onClick={() => handleEscrow(true)}>
                Escrow
              </Button>
              <Button variant="primary" onClick={() => handleEscrow(false)}>
                Non Escrow
              </Button>
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? ( // Show spinner while loading
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <Row>
                  <Col xxl={12}>
                    <MyLeads />
                  </Col>
                </Row>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Projects;
