import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Spinner,
  Modal,
  Col,
  Row,
  Form,
  Alert,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  applyLead,
  getAplliedLeads,
  getLeads,
  getLeadById,
  declineLead,
  checkPaymentStatus,
  declineOpenLead,
} from "../../../slices/thunk";
import withRouter from "Common/withRouter";
import { Link, useNavigate } from "react-router-dom";
import { decode } from "punycode";
import { useTranslation } from "react-i18next";

interface SalePriceProps {
  data: any;
}

interface JwtPayload {
  userType: string;
}

const DiscountCard: React.FC<SalePriceProps> = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // ✅ React knows to update


  //const data = useSelector((state: any) => state.Leads.singleLead);
  const urlParams = new URLSearchParams(window.location.search);
  const [modal_small, setmodal_small] = useState<boolean>(false);
  const [modal_payment_message, setModal_payment_message] =
    useState<boolean>(false);
  const [feedbackmodal, setfeedback_modal] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState<boolean>(false);
  const [leadData, setLeadData] = useState<any>({});
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  const id = urlParams.get("id");
  const token = localStorage.getItem("authUser");
  const [loading, setLoading] = useState(false);
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error("Token is null");
  }

  const dispatch: any = useDispatch();
  const [budget, setBudget] = useState<string>("");
  const [isLeadAccepted, setLeadAccepted] = useState<Boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  function tog_small() {
    setmodal_small(!modal_small);
  }

  function tog_payment_message() {
    setModal_payment_message(!modal_payment_message);
  }

  function feedBack() {
    setfeedback_modal(!feedbackmodal);
  }
  const fetchLeadData = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getLeadById(id));
      setLeadData(response);
    } catch (error) {
      console.error("Error fetching lead data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchLeadData();
  }, [id]);
  useEffect(() => {
    if (leadData && leadData.uq_question) {
      const questions = JSON.parse(leadData.uq_question);
      questions.forEach((item: any) => {
        if (item.label === "Max Budget") setBudget(item.value);
      });
    }
  }, [leadData]);

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await dispatch(checkPaymentStatus());
        setPaymentStatus(response.data);
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    };

    fetchPaymentStatus();
  }, [dispatch]);

  useEffect(() => {
    if (
      leadData &&
      ["ACCEPTED", "COMPLETED", "DISPUTE"].includes(leadData.lead_job_status) &&
      leadData.lead_contractor === decoded?.userId.toString()
    ) {
      setLeadAccepted(true);
    } else {
      setLeadAccepted(false);
    }
  }, [leadData, decoded?.userId]);

  const handleProjectUpdate = async () => {
    setLoading(true);
    try {
      await dispatch(
        applyLead(leadData.id, selectedStatus, leadData.lead_sub_cat)
      );
      await dispatch(getAplliedLeads());
      await fetchLeadData();
      tog_small();
    } catch (error) {
      console.error("Error applying lead:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async () => {
    setLoading(true);
    try {
      await dispatch(declineLead(leadData.id, "OPEN"));
      await dispatch(getAplliedLeads());
      await fetchLeadData();
    } catch (error) {
      console.error("Error declining lead:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDecline = async () => {
    setLoading(true);
    try {
      await dispatch(declineOpenLead(id));
      await dispatch(getLeads());
      setNotification({
        status: true,
        message: "Lead Declined Successfully",
        variant: "success",
      });
      navigate("/pages-projects");
    } catch (error) {
      console.error("Error declining open lead:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLead = () => {
    setLoading(true);
    dispatch(applyLead(leadData.id, "ACCEPTED", leadData.lead_sub_cat))
      .then(() => {
        dispatch(getAplliedLeads());
        return dispatch(getLeadById(id)).then((resp: any) => {
          setLeadData(resp);
        });
      })
      .catch((error: any) => {
        console.error("Error applying lead:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(e.target.value);
  };

  const handleAlertClick = () => setNotification(null);

  return (
    <React.Fragment>
      <div>
        {notification && notification.status && (
          <div
            style={{
              position: "absolute",
              top: "5%",
              right: "24px",
              zIndex: 99999,
            }}
          >
            <Alert variant={notification.variant} onClick={handleAlertClick}>
              {notification.message}
            </Alert>
          </div>
        )}
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Card className="overflow-hidden ribbon-box">
            <span className="ribbon ribbon-danger rounded-end">New</span>
            <Card.Body className="bg-info-subtle text-center learning-widgets d-flex align-items-center justify-content-center">
              <img
                src={
                  "https://res.cloudinary.com/dr6rh7hon/image/upload/v1719393122/1912.i121.048.P.m005.c33.isometric_marketing_strategy_business_set-09_x5w7d4.jpg"
                }
                alt=""
                className="avatar-lg"
              />
              <img
                src={
                  "https://res.cloudinary.com/dr6rh7hon/image/upload/v1719393122/1912.i121.048.P.m005.c33.isometric_marketing_strategy_business_set-09_x5w7d4.jpg"
                }
                alt=""
                className="effect"
              />
            </Card.Body>
            <Card.Body>
              <h4 className="text-truncate text-capitalize">
                ${leadData && leadData.lead_budget}
              </h4>
              {decoded.userType === "contractor" && leadData && (
                <h6>
                 {t("Tradesmens Will Charge")}  ${leadData.tradesmens_commission}
                </h6>
              )}
              {decoded.userType === "contractor" ? (
                <div className="hstack gap-2">
                  {isLeadAccepted ? (
                    leadData && leadData.lead_job_status === "COMPLETED" ? (
                      <Button
                        variant="success"
                        className="w-100"
                        onClick={feedBack}
                      >
                        Job Completed - Give Feedback
                      </Button>
                    ) : leadData && leadData.lead_job_status === "DISPUTE" ? (
                      <Button variant="danger" className="w-100">
                        Dispute
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        className="w-100"
                        onClick={tog_small}
                      >
                        Update Status
                      </Button>
                    )
                  ) : paymentStatus ? (
                    <Button
                      variant="secondary"
                      className="w-100"
                      onClick={handleLead}
                    >
                      Apply Now
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      className="w-100"
                      onClick={tog_payment_message}
                    >
                      Apply Now
                    </Button>
                  )}
                  {leadData && leadData.lead_is_req == "1" ? (
                    <Button
                      variant="danger"
                      className="w-100"
                      onClick={handleDecline}
                    >
                      Decline
                    </Button>
                  ) : (
                    <Button
                      variant="danger"
                      className="w-100"
                      onClick={handleOpenDecline}
                    >
                      Decline
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        )}

        <Modal
          size="sm"
          show={modal_small}
          onHide={() => {
            tog_small();
          }}
        >
          <Modal.Header>Update Status</Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <Form.Select
                  className="mb-3"
                  aria-label="Default select example"
                  onChange={handleStatusChange}
                  value={selectedStatus}
                >
                  <option selected>Select your Status </option>
                  <option value="COMPLETED">Completed</option>
                  <option value="DISPUTE">Disput</option>
                </Form.Select>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              className="w-100"
              onClick={() => handleProjectUpdate()}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="sm"
          show={modal_payment_message}
          onHide={() => {
            tog_payment_message();
          }}
        >
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <img
                  className="img-fluid"
                  src="https://res.cloudinary.com/dvnxszfqa/image/upload/v1719388593/2306.i402.024.F.m004.c9.Credit_score_flat_background_qfejgd.jpg"
                ></img>
                <h6 className="text-center">
                  Your payment has not been verified yet. Please proceed with
                  the verification process.{" "}
                  <Link to="https://app.thetradesmens.com/pages-profile-settings#financial">
                    Click to Verify Payment
                  </Link>
                </h6>
              </Col>
            </Row>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="success" className="w-100" onClick={() => handleProjectUpdate()}>Submit</Button>
          </Modal.Footer> */}
        </Modal>

        <Modal
          size="sm"
          show={feedbackmodal}
          onHide={() => {
            feedBack();
          }}
        >
          <Modal.Header>FeedBack</Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <Form.Group>
                  <Form.Label>Give Feedback</Form.Label>
                  <textarea
                    className="form-control"
                    id="feedBack"
                    rows={3}
                  ></textarea>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              className="w-100"
              onClick={() => handleProjectUpdate()}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default withRouter(DiscountCard);