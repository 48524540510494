import React, { useEffect, useState } from "react";
import { Container, Row, Spinner, Alert } from "react-bootstrap";
import Right from "./right";
import BreadCrumb from "Common/BreadCrumb";
import Personal from "./personal";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  getProfileData,
  getUserData,
  updateCompany,
  getPaymentStatus,
  sendDocToS3,
  createUserProfile,
  updateUserProfile,
  createCompany,
  geCompanyData,
  updateUserImageProfile,
  updateUserSocialProfile,
  getRecentWork,
} from "../../../slices/thunk";
import HomeOwnerPersonal from "./homeOwnerPersonal";
import RealEstateAgentPersonal from "./realEstateAgentPersonal";
import withRouter from "Common/withRouter";
import { useTranslation, withTranslation } from "react-i18next";

interface JwtPayload {
  userType: string;
}

const ProfileSetting = (props: any) => {
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error("Token is null");
  }
  const { t } = useTranslation(); // ✅ React knows to update


  document.title = "Profile Settings";
  const location = useLocation();
  let user: any = [];
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({
    show: false,
    variant: "",
    message: "",
  }); // Notification state

  const userData = useSelector((state: any) => state.UserProfile.userData);
  user = useSelector((state: any) => state.UserProfile.user);
  const company = useSelector((state: any) => state.ContractorCompany.company);
  const paymentverified = useSelector(
    (state: any) => state.Payment.paymentStatus
  );

  const [coverImagePath, setCoverImagePath] = useState<string>("");
  const [profileImagePath, setProfileImagePath] = useState<string>("");
  const [companyLicencePath, setCompanyLicencePath] = useState<string>("");
  const [contractorLicencePath, setContractorLicencePath] =
    useState<string>("");
  const [insurancePath, setInsurancePath] = useState<string>("");
  const [profileVideoPath, setProfileVideoPath] = useState<string>("");
  const [companyLogoPath, setCompanyLogoPath] = useState<string>("");
  const [companyLicenceLoading, setCompanyLicenceLoading] = useState(false);
  const [contInsuranceLoading, setContInsuranceLoading] = useState(false);
  const [contLicenceLoading, setContLicenceLoading] = useState(false);
  const [companyLogoLoading, setCompLogoLoading] = useState(false);
  const [profVideoLoading, setProfVideoLoading] = useState(false);
  const [socialSuccess, setSocialSuccess] = useState(false);
  const [activeTabHandle, setActiveTabHandle] = useState("first");
  const [socialItems, setSocialItems] = useState("");

  const dispatch: any = useDispatch();

  //console.log(user, "useruseruser");
  useEffect(() => {
    setLoading(true);
    dispatch(getProfileData())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getPaymentStatus())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getUserData())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(geCompanyData())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const handleUser = (values: any) => {
    let newCover = null;
    if (coverImagePath !== "") {
      newCover = coverImagePath;
    } else if (userData && userData.ua_cover_pic !== null) {
      newCover = userData.ua_cover_pic;
    }
    let newProfile = null;
    if (profileImagePath !== "") {
      newProfile = profileImagePath;
    } else if (userData && userData.ua_profile_pic !== null) {
      newProfile = userData.ua_profile_pic;
    }
    let newSocial = null;
    if (socialItems !== "") {
      newSocial = socialItems;
    } else if (userData && userData.ua_social_url !== null) {
      newSocial = userData.ua_social_url;
    }

    if (userData) {
      setLoading(true);
      dispatch(updateUserProfile(values, newCover, newProfile, newSocial))
        .then(() => {
          dispatch(getProfileData());
          if (decoded.userType === "homeowner") {
            setActiveTabHandle("first");
          } else {
            setActiveTabHandle("second");
          }

          setLoading(false);
          setNotification({
            show: true,
            variant: "success",
            message: "User profile created successfully!",
          });
        })
        .catch(() => {
          setLoading(false);
          setNotification({
            show: true,
            variant: "danger",
            message: "Failed to create user profile!",
          });
        });
    } else {
      setLoading(true);
      dispatch(createUserProfile(values, newCover, newProfile, newSocial))
        .then(() => {
          dispatch(getProfileData());
          if (decoded.userType === "homeowner") {
            setActiveTabHandle("first");
          } else {
            setActiveTabHandle("second");
          }
          setLoading(false);
          setNotification({
            show: true,
            variant: "success",
            message: "User profile updated successfully!",
          });
        })
        .catch(() => {
          setLoading(false);
          setNotification({
            show: true,
            variant: "danger",
            message: "Failed to update user profile!",
          });
        });
    }
  };

  const handleCompany = (values: any) => {
    let newCompanyLicence = null;
    if (companyLicencePath !== "") {
      newCompanyLicence = companyLicencePath;
    } else if (company && company.cc_licence_documentation_url !== null) {
      newCompanyLicence = company.cc_licence_documentation_url;
    }

    let newContractorLicence = null;
    if (contractorLicencePath !== "") {
      newContractorLicence = contractorLicencePath;
    } else if (company && company.cc_contractor_documentation_url !== null) {
      newContractorLicence = company.cc_contractor_documentation_url;
    }

    let newInsurance = null;
    if (insurancePath !== "") {
      newInsurance = insurancePath;
    } else if (company && company.cc_insurance_documentation_url !== null) {
      newInsurance = company.cc_insurance_documentation_url;
    }

    let newVideoUrl = null;
    if (profileVideoPath !== "") {
      newVideoUrl = profileVideoPath;
    } else if (company && company.cc_video_url !== null) {
      newVideoUrl = company.cc_video_url;
    }

    let newLogoUrl = null;
    if (companyLogoPath !== "") {
      newLogoUrl = companyLogoPath;
    } else if (company && company.cc_logo_url !== null) {
      newLogoUrl = company.cc_logo_url;
    }

    if (company != null) {
      setLoading(true);
      dispatch(
        updateCompany(
          values,
          newCompanyLicence,
          newContractorLicence,
          newInsurance,
          newVideoUrl,
          newLogoUrl
        )
      )
        .then(() => {
          dispatch(geCompanyData());
          setLoading(false);
          setActiveTabHandle("third");
          setNotification({
            show: true,
            variant: "success",
            message: "Company profile created successfully!",
          });
        })
        .catch(() => {
          setLoading(false);
          setNotification({
            show: true,
            variant: "danger",
            message: "Failed to create company profile!",
          });
        });
    } else {
      //console.log("In create");
      setLoading(true);
      dispatch(
        createCompany(
          values,
          newCompanyLicence,
          newContractorLicence,
          newInsurance,
          newVideoUrl,
          newLogoUrl
        )
      )
        .then(() => {
          dispatch(geCompanyData());
          setLoading(false);
          setActiveTabHandle("third");
          setNotification({
            show: true,
            variant: "success",
            message: "Company profile updated successfully!",
          });
        })
        .catch(() => {
          setLoading(false);
          setNotification({
            show: true,
            variant: "danger",
            message: "Failed to update company profile!",
          });
        });
    }
  };

  const handleSocialChange = (social: any) => {
    setSocialItems(social);
  };

  const handleCoverImageChange = (type: string, filePath: any) => {
    //console.log(filePath, type, "ddddssss");
    if (type === "profile") {
      setLoading(true);
      dispatch(sendDocToS3(type, filePath))
        .then((resp: any) => {
          let userImg = {
            img: resp,
            imgType: "profilePic",
          };
          dispatch(updateUserImageProfile(userImg)).then(() => {
            dispatch(getProfileData());
          });
          setProfileImagePath(resp);
          setLoading(false);
        })
        .catch((err: any) => {
          // Handle error if needed
          console.error("Error:", err);
          setLoading(false);
        });
    }

    if (type === "cover") {
      setLoading(true);
      dispatch(sendDocToS3(type, filePath))
        .then((resp: any) => {
          let userImg = {
            img: resp,
            imgType: "coverPic",
          };
          dispatch(updateUserImageProfile(userImg)).then(() => {
            dispatch(getProfileData());
          });
          setCoverImagePath(resp);
          setLoading(false);
        })
        .catch((err: any) => {
          // Handle error if needed
          console.error("Error:", err);
          setLoading(false);
        });
    }

    if (type === "companyLicence") {
      setCompanyLicenceLoading(true);
      dispatch(sendDocToS3(type, filePath))
        .then((resp: any) => {
          setCompanyLicencePath(resp);
          setCompanyLicenceLoading(false);
        })
        .catch((err: any) => {
          // Handle error if needed
          console.error("Error:", err);
        });
    }

    if (type === "insurance") {
      setContInsuranceLoading(true);
      dispatch(sendDocToS3(type, filePath))
        .then((resp: any) => {
          setInsurancePath(resp);
          setContInsuranceLoading(false);
        })
        .catch((err: any) => {
          // Handle error if needed
          setContInsuranceLoading(false);
          console.error("Error:", err);
        });
    }

    if (type === "contractorLicence") {
      setContLicenceLoading(true);
      dispatch(sendDocToS3(type, filePath))
        .then((resp: any) => {
          setContractorLicencePath(resp);
          setContLicenceLoading(false);
        })
        .catch((err: any) => {
          // Handle error if needed
          setContLicenceLoading(false);
          console.error("Error:", err);
        });
    }

    if (type === "companyLogo") {
      setCompLogoLoading(true);
      dispatch(sendDocToS3(type, filePath))
        .then((resp: any) => {
          setCompanyLogoPath(resp);
          setCompLogoLoading(false);
        })
        .catch((err: any) => {
          // Handle error if needed
          setCompLogoLoading(false);
          console.error("Error:", err);
        });
    }

    if (type === "profileVideo") {
      setProfVideoLoading(true);
      dispatch(sendDocToS3(type, filePath))
        .then((resp: any) => {
          setProfileVideoPath(resp);
          setProfVideoLoading(false);
        })
        .catch((err: any) => {
          // Handle error if needed
          setProfVideoLoading(false);
          console.error("Error:", err);
        });
    }

    if (type === "profile") {
      dispatch(sendDocToS3(type, filePath))
        .then((resp: any) => {
          setProfileImagePath(resp);
        })
        .catch((err: any) => {
          // Handle error if needed
          console.error("Error:", err);
        });
    }
  };

  function handleRemoveImage(type: any) {
    if (type === "insuranceDocumentation") {
      setInsurancePath("");
    }
    if (type === "licenceDocumentation") {
      setCompanyLicencePath("");
    }
    if (type === "contractorLicence") {
      setContractorLicencePath("");
    }
    if (type === "profileVideo") {
      setProfileVideoPath("");
    }
    if (type === "companyLogo") {
      setCompanyLogoPath("");
    }
    //console.log(type, "Tett Remove");
  }

  const handleTab = (type: any) => {
    setActiveTabHandle(type);
  };

  //handle social submit
  const handleSocialSubmit = () => {
    setLoading(true);
    dispatch(updateUserSocialProfile(socialItems));
    dispatch(getUserData());
    dispatch(geCompanyData());
    setSocialSuccess(true);
    setNotification({
      show: true,
      variant: "success",
      message: "User Social Media url's added successfully!",
    });
    setLoading(false);
  };

  let paymentCount = paymentverified === 1 ? 40 : 0;
  let userProfileCount =
    userData && userData.ua_completed != null
      ? Number(userData.ua_completed)
      : 0;
  let userCompanyCount =
    company && company.cc_completed != null ? Number(company.cc_completed) : 0;

  const profileCompleted = userProfileCount + userCompanyCount + paymentCount;
  //console.log(company, "1234")

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const payment = urlParams.get("payment");
    if (payment === "success") {
      setActiveTabHandle("second");
      setNotification({
        show: true,
        variant: "success",
        message:
          "Payment was successful! Your video payment status has been updated.",
      });
    }
    if (payment === "failure") {
      setActiveTabHandle("second");
      setNotification({
        show: true,
        variant: "danger",
        message:
          "Payment was failed! Your video payment status has been updated.",
      });
    }
  }, [location]);

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust opacity here
              zIndex: 99999999, // Ensure spinner is on top
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ position: "relative" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t("Loading")} </span>
              </Spinner>
            </div>
          </div>
        )}
        {notification.show && (
          <Alert
            variant={notification.variant}
            onClose={() =>
              setNotification({ show: false, variant: "", message: "" })
            }
            dismissible
          >
            {notification.message}
          </Alert>
        )}
        <Container fluid>
          <BreadCrumb title="Profile Settings" pageTitle="Pages" />
          <Row>
            <Right
              userData={user[0]}
              data={userData}
              profileCompleted={profileCompleted}
              onFileChange={handleCoverImageChange}
              onSocialChange={handleSocialChange}
              createUserData={handleSocialSubmit}
              successSoc={socialSuccess}
              isLoading={loading}
            />
            {decoded.userType === "contractor" ||
            decoded.userType === "admin" ||
            decoded.userType === "real_estate_agent" ||
            decoded.userType === "subcontractor" ? (
              <Personal
                data={userData}
                props={props}
                userType={decoded.userType}
                userD={user[0]}
                companyD={company}
                isLoading={loading}
                licenceImage={
                  company && company.cc_licence_documentation_url != null
                    ? company.cc_licence_documentation_url
                    : companyLicencePath
                }
                contractorLicenceImage={
                  company && company.cc_contractor_documentation_url != null
                    ? company.cc_contractor_documentation_url
                    : contractorLicencePath
                }
                insuranceImage={
                  company && company.cc_insurance_documentation_url != null
                    ? company.cc_insurance_documentation_url
                    : insurancePath
                }
                createUserData={handleUser}
                createCompanyData={handleCompany}
                onFileChange={handleCoverImageChange}
                onRemoveImage={handleRemoveImage}
                isPaymentVerified={paymentverified}
                companyLicLoading={companyLicenceLoading}
                insuranceLoading={contInsuranceLoading}
                contLicLoading={contLicenceLoading}
                compLogo={
                  company && company.cc_logo_url != null
                    ? company.cc_logo_url
                    : companyLogoPath
                }
                compLogoLoading={companyLogoLoading}
                profileVideo={
                  company && company.cc_video_url != null
                    ? company.cc_video_url
                    : profileVideoPath
                }
                profileVidLoading={profVideoLoading}
                activeT={activeTabHandle}
                handleActivetab={handleTab}
              />
            ) : (
              <HomeOwnerPersonal
                data={userData}
                userD={user[0]}
                companyD={company}
                isLoading={loading}
                licenceImage={
                  company && company.cc_licence_documentation_url != null
                    ? company.cc_licence_documentation_url
                    : companyLicencePath
                }
                contractorLicenceImage={
                  company && company.cc_contractor_documentation_url != null
                    ? company.cc_contractor_documentation_url
                    : contractorLicencePath
                }
                insuranceImage={
                  company && company.cc_insurance_documentation_url != null
                    ? company.cc_insurance_documentation_url
                    : insurancePath
                }
                createUserData={handleUser}
                createCompanyData={handleCompany}
                onFileChange={handleCoverImageChange}
                onRemoveImage={handleRemoveImage}
                isPaymentVerified={paymentverified}
                companyLicLoading={companyLicenceLoading}
                insuranceLoading={contInsuranceLoading}
                contLicLoading={contLicenceLoading}
                compLogo={
                  company && company.cc_logo_url != null
                    ? company.cc_logo_url
                    : companyLogoPath
                }
                compLogoLoading={companyLogoLoading}
                profileVideo={
                  company && company.cc_video_url != null
                    ? company.cc_video_url
                    : profileVideoPath
                }
                profileVidLoading={profVideoLoading}
                activeT={activeTabHandle}
                handleActivetab={handleTab}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileSetting));
