import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  Card,
  ButtonGroup,
  Dropdown,
  Table,
  Modal,
  Spinner,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import {
  getSingleProjectById,
  downloadCancelForm,
} from "../../../../slices/thunk";
import { getAuthHeader } from "../../../../helpers/jwt-token-access/auth-token-header";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import HomeContract from "../contracts";
import { Link } from "react-router-dom";
import AddContract from "../AddContract";
import SignatureCanvas from "react-signature-canvas";
import { jwtDecode } from "jwt-decode";
import ChangeOrder from "../AddContract/change-order";

import apiUrl from "slices/apiEndpoint";
import { useTranslation } from "react-i18next";


interface ProjectDetailsProps {
  projectDetails: any;
  disable: any;
}
interface JwtPayload {
  userType: string;
}
const Contract: React.FC<ProjectDetailsProps> = ({
  projectDetails,
  disable,
}) => {
  const dispatch: any = useDispatch();
  const contractors = useSelector(
    (state: any) => state.Proposal.searchContractors
  );
  const { t } = useTranslation(); // ✅ React knows to update


  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  }

  let project: any = null;
  project = useSelector((state: any) => state.Contract.project);

  const [subCont, setSubContData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [subContId, setSubContId] = useState<string>("");
  const [modal_contract, setmodal_contract] = useState(false);
  const [modal_change_order, setmodal_change_order] = useState(false);
  const [modal_sign_contract, setModal_sign_contract] = useState(false);
  const [modal_sign_change_order, setModal_sign_change_order] = useState(false);
  const [selectedCoId, setSelectedCoId] = useState(false);
  const [modal_view_change_order, setModal_view_change_order] = useState(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [newContractors, setNewContractors] = useState<any>([]);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  const sigCanvasRef = useRef<SignatureCanvas | null>(null);

  const clearSignature = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
    }
  };

  useEffect(() => {
    if (notification && notification.status) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      // Cleanup the timeout if the component unmounts or notification changes
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleAlertClick = () => {
    setNotification(null);
  };

  const submitSignature = async () => {
    const headers = getAuthHeader();
    if (!headers) {
      return;
    }
    if (sigCanvasRef.current && !sigCanvasRef.current.isEmpty()) {
      setLoading(true); // Set loading to true when submitting signature
      const signatureDataURL = sigCanvasRef.current.toDataURL();
      const signatureBlob = await fetch(signatureDataURL).then((res) =>
        res.blob()
      );
      const formData = new FormData();
      formData.append("file", signatureBlob, "signature.png");

      try {
        const response = await axios.post(
          `${apiUrl}/contract/sign-contract/${decoded.userType}/${project.p_id}`,
          formData,
          {
            headers: {
              ...headers,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response) {
          setModal_sign_contract(false);
          if (project !== "") {
            setLoading(true);
            dispatch(getSingleProjectById(project.p_id)).finally(() =>
              setLoading(false)
            );
          }
          setNotification({
            status: true,
            message: "Contract Signed Successfully",
            variant: "success",
          });
        } else {
          console.error("Failed to upload signature");
        }
      } catch (error) {
        console.error("Error uploading signature:", error);
      } finally {
        setLoading(false); // Set loading to false after submission completes
      }
    } else {
      alert("Please provide a signature first.");
    }
  };

  const submitChangeOrderSignature = async () => {
    const headers = getAuthHeader();
    if (!headers) {
      return;
    }
    if (sigCanvasRef.current && !sigCanvasRef.current.isEmpty()) {
      setLoading(true); // Set loading to true when submitting signature
      const signatureDataURL = sigCanvasRef.current.toDataURL();
      const signatureBlob = await fetch(signatureDataURL).then((res) =>
        res.blob()
      );
      const formData = new FormData();
      formData.append("file", signatureBlob, "signature.png");

      try {
        const response = await axios.post(
          `${apiUrl}/contract/sign-change-order/${decoded.userType}/${selectedCoId}`,
          formData,
          {
            headers: {
              ...headers,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response) {
          setModal_sign_change_order(false);
          if (project !== "") {
            setLoading(true);
            dispatch(getSingleProjectById(project.p_id)).finally(() =>
              setLoading(false)
            );
          }
          setNotification({
            status: true,
            message: "Change Order Signed Successfully",
            variant: "success",
          });
        } else {
          console.error("Failed to upload signature");
        }
      } catch (error) {
        console.error("Error uploading signature:", error);
      } finally {
        setLoading(false); // Set loading to false after submission completes
      }
    } else {
      alert("Please provide a signature first.");
    }
  };

  useEffect(() => {
    if (contractors.length) {
      const newData = contractors.map((item: any) => {
        return { label: item.name, value: item.id };
      });
      setNewContractors(newData);
    }
  }, [contractors]);

  function tog_contract() {
    setmodal_contract(!modal_contract);
  }
  function tog_change_order() {
    setmodal_change_order(!modal_change_order);
  }

  function tog_sign_contract() {
    setModal_sign_contract(!modal_sign_contract);
  }
  function tog_sign_change_order(id: any) {
    setSelectedCoId(id);
    setModal_sign_change_order(!modal_sign_change_order);
  }
  function tog_view_change_order() {
    setModal_view_change_order(!modal_view_change_order);
  }

  useEffect(() => {
    // Function to handle the click event
    const handleClickOutside = (event: MouseEvent) => {
      // Get the modal elements
      const backdropElement = document.getElementById("staticBackdrop");

      // Check if the click is outside the modal
      if (backdropElement && !backdropElement.contains(event.target as Node)) {
        // Close the modals if they are open
        if (modal_contract) {
          setmodal_contract(false);
        }
        if (modal_sign_contract) {
          setModal_sign_contract(false);
        }
        if (modal_change_order) {
          setmodal_change_order(false);
        }
        if (modal_sign_change_order) {
          setModal_sign_change_order(false);
        }
        if (modal_view_change_order) {
          setModal_view_change_order(false);
        }
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    modal_contract,
    modal_sign_contract,
    modal_change_order,
    modal_change_order,
    modal_view_change_order,
  ]);

  const handleCloseModel = (type: any) => {
    if (type) {
      setmodal_contract(false);
      setNotification({
        status: true,
        message: "Contract Created Successfully",
        variant: "success",
      });
    }
  };

  const downloadForm = () => {
    setLoading(true);
    dispatch(downloadCancelForm()).then((resp: any) => {
      if (resp) {
        window.open(resp.data[0].url, "_blank");
        setLoading(false);
      }
    });
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
     <Card>
        <Card.Body>
          <Row className="g-3 align-items-center mb-3">
            <h5 className="card-title mb-3">{t("Homeowner Contract")}</h5>
            <Col lg={12}>
              {decoded.userType === "contractor" ||
                (decoded.userType === "testContractor" && (
                  <p className="mb-4">
                    <b>{t("Thank you for using the Tradesmen’s, LLC.")}</b> {t("As a Contractor, it is incumbent upon you to ensure delivery of the Notice of Right to Cancellation of the contract to each owner of the property. Consumer protection laws require that the Contractor provide two (2) copies of the Notice of Right to Cancel to each home owner. If you do not hand deliver the Notice of Right to Cancellation to each of the homeowners that have signed the contract, you may not be able to recover in a court action, depending on your jurisdiction and your contract may not be valid. If you have sought asset protection through the Tradesmen’s, LLC you shall not be able to access asset protection as you are required to comply with all aspects of the requirements of having used the Tradesmen’s services AND complied with all consumer protection laws within your jurisdiction.")}
                  </p>
                ))}
              <div className="table-responsive">
                <Table className="table-striped table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th scope="col">{t("ID")}</th>
                      <th scope="col">{t("Name")}</th>
                      <th scope="col">{t("Phone Number")}</th>
                      <th scope="col">{t("Email")}</th>
                      <th scope="col">{t("Status")}</th>
                      <th scope="col">{t("Date")}</th>
                      <th scope="col">{t("Contract")}</th>
                      <th scope="col">{t("Create/Update Contract")}</th>
                      <th scope="col">{t("Sign Contract")}</th>
                      <th scope="col">{t("Download")}</th>
                    </tr>
                  </thead>
                  {project ? (
                    <tbody>
                      <tr>
                        <td className="fw-medium">
                          {project && project.home_owner
                            ? project.home_owner.user_id
                            : ""}
                        </td>
                        <td>
                          {project && project.home_owner
                            ? project.home_owner.user_first_name
                            : ""}
                        </td>
                        <td>
                          {project && project.home_owner
                            ? project.home_owner.user_phone
                            : ""}
                        </td>
                        <td>
                          {project && project.home_owner
                            ? project.home_owner.user_email
                            : ""}
                        </td>
                        <td>
                          <span className="badge bg-danger">{t("Pending")}</span>
                        </td>
                        <td>{t("Nov 14, 2021")}</td>
                        {decoded.userType === "subcontractor" ? (
                          ""
                        ) : (
                          <td>
                            {project && project.contract ? (
                              <a
                                target="_blank"
                                href={project.contract.pc_contract_url}
                              >
                                <i className="ri-download-2-line fs-base lh-1 align-middle"></i>
                              </a>
                            ) : (
                              ""
                            )}
                          </td>
                        )}
                        {decoded.userType === "subcontractor" ||
                        decoded.userType === "homeowner" ? (
                          ""
                        ) : (
                          <td>
                            {project && project.p_status !== "LIVE" ? (
                              !disable ? (
                                project && project.contract ? (
                                  <Button
                                    type="button"
                                    variant="warning"
                                    onClick={() => tog_contract()}
                                  >
                                    {t("Update Contract")}
                                  </Button>
                                ) : (
                                  <Button
                                    type="button"
                                    variant="primary"
                                    onClick={() => tog_contract()}
                                  >
                                    {t("Create Contract")}
                                  </Button>
                                )
                              ) : (
                                ""
                              )
                            ) : decoded.userType != "homeowner" ? (
                              <Button
                                type="button"
                                variant="primary"
                                onClick={() => tog_change_order()}
                              >
                                {t("Create Change Order")}
                              </Button>
                            ) : (
                              ""
                            )}
                          </td>
                        )}
                        <td>
                          {project && project.p_status !== "LIVE" ? (
                            project.contract ? (
                              <Button
                                type="button"
                                variant="danger"
                                onClick={() => tog_sign_contract()}
                                disabled={
                                  decoded.userType === "subcontractor"
                                    ? true
                                    : false
                                }
                              >
                                {t("Sign Contract")}
                              </Button>
                            ) : (
                              ""
                            )
                          ) : (
                            <Button
                              type="button"
                              variant="danger"
                              onClick={() => tog_view_change_order()}
                            >
                              {t("View Change Orders")}
                            </Button>
                          )}
                        </td>
                        <td>
                          {decoded.userType === "contractor" ||
                          decoded.userType === "testContractor" ? (
                            <Button
                              onClick={downloadForm}
                              type="button"
                              variant="success"
                            >
                              {t("Download Right To Cancellation")}
                            </Button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    ""
                  )}
                </Table>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/*Contract Modal */}

      <Modal
  size="xl"
  show={modal_contract}
  onHide={() => {
    tog_contract();
  }}
  backdrop={"static"}
  id="staticBackdrop"
  centered
  data-bs-focus="false"
>
  <Modal.Header
    className="modal-title fw-bold"
    id="staticBackdropLabel"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    {t("Contract")}
  </Modal.Header>
  <Modal.Body
    className="p-5"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    <AddContract closeModal={handleCloseModel} />
  </Modal.Body>
</Modal>


      {/*Sign Contract Modal */}

      <Modal
  size="sm"
  show={modal_sign_contract}
  onHide={() => {
    tog_sign_contract();
  }}
  backdrop={"static"}
  id="staticBackdrop"
  centered
  data-bs-focus="false"
>
  <Modal.Header
    className="modal-title fw-bold"
    id="staticBackdropLabel"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    {t("Sign Contract")}
  </Modal.Header>
  <Modal.Body
    className="p-5"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    <SignatureCanvas
      ref={sigCanvasRef}
      penColor="black"
      canvasProps={{
        width: 200,
        height: 200,
        className: "sigCanvas",
        style: { backgroundColor: "white" },
      }}
    />
    <div className="d-flex mt-2 justify-content-between">
      <Button variant="primary" onClick={clearSignature}>
        {t("Clear")}
      </Button>
      <Button variant="success" onClick={submitSignature}>
        {t("Submit")}
      </Button>
    </div>
  </Modal.Body>
</Modal>


      {/*Change Order Modal */}

      <Modal
  size="xl"
  show={modal_change_order}
  onHide={() => {
    tog_change_order();
  }}
  backdrop={"static"}
  id="staticBackdrop"
  centered
  data-bs-focus="false"
>
  <Modal.Header
    className="modal-title fw-bold"
    id="staticBackdropLabel"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    {t("Change Order Contract")}
  </Modal.Header>
  <Modal.Body
    className="p-5"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    <ChangeOrder />
  </Modal.Body>
</Modal>


      {/*Sign Contract Modal */}

      <Modal
  size="sm"
  show={modal_sign_change_order}
  onHide={() => {
    tog_sign_change_order(null);
  }}
  backdrop={"static"}
  id="staticBackdrop"
  centered
  data-bs-focus="false"
>
  <Modal.Header
    className="modal-title fw-bold"
    id="staticBackdropLabel"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    {t("sign_change_order")}
  </Modal.Header>
  <Modal.Body
    className="p-5"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    <SignatureCanvas
      ref={sigCanvasRef}
      penColor="black"
      canvasProps={{
        width: 200,
        height: 200,
        className: "sigCanvas",
        style: { backgroundColor: "white" },
      }}
    />
    <div className="d-flex mt-2 justify-content-between">
      <Button variant="primary" onClick={clearSignature}>
        {t("clear")}
      </Button>
      <Button variant="success" onClick={submitChangeOrderSignature}>
        {t("submit")}
      </Button>
    </div>
  </Modal.Body>
</Modal>


      {/*Change Orders */}

      <Modal
  size="lg"
  show={modal_view_change_order}
  onHide={() => {
    tog_view_change_order();
  }}
  backdrop={"static"}
  id="staticBackdrop"
  centered
  data-bs-focus="false"
>
  <Modal.Header
    className="modal-title fw-bold"
    id="staticBackdropLabel"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    {t("change_orders")}
  </Modal.Header>
  <Modal.Body
    className="p-5"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    <Col lg={12}>
      <div className="table-responsive">
        <Table className="table-striped table-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col">{t("id")}</th>
              <th scope="col">{t("created_at")}</th>
              <th scope="col">{t("contract")}</th>
              <th scope="col">{t("sign_change_order")}</th>
              <th scope="col">{t("submit_change_order")}</th>
            </tr>
          </thead>
          {project &&
          project.change_orders &&
          project.change_orders.length > 0 ? (
            <tbody>
              {project.change_orders.map((item: any) => (
                <tr key={item.co_id}>
                  <td className="fw-medium">{item.co_id}</td>
                  <td>{item.co_created_at}</td>
                  <td>
                    <a target="_blank" href={item.co_contract_url}>
                      <i className="ri-download-2-line fs-base lh-1 align-middle"></i>
                    </a>
                  </td>
                  <td>
                    <Button
                      type="button"
                      variant="danger"
                      onClick={() => tog_sign_change_order(item.co_id)}
                    >
                      {t("sign_change_order_contract")}
                    </Button>
                  </td>
                  <td>
                    <Button type="button" variant="success">
                      {t("submit_contract")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={5}>{t("no_change_orders_available")}</td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
    </Col>
  </Modal.Body>
</Modal>

    </React.Fragment>
  );
};

export default Contract;
