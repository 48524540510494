import React, { useEffect } from "react";
//import Scss
import "assets/scss/themes.scss";

//imoprt Route
import Route from "Routes/Index";

import { useSelector, useDispatch } from "react-redux";
import { isTokenExpire } from "./slices/thunk";
import { TourProvider } from "@reactour/tour";
import { useTranslation } from "react-i18next";
// Activating fake backend

const App = (props: any) => {
  const dispatch: any = useDispatch();
  const {t} = useTranslation()
  useEffect(() => {
    let token = window.localStorage.getItem("token");
    if (token) {
      dispatch(isTokenExpire());
    }
  }, [dispatch]);
  const steps = [
    {
      selector: ".step-1",
      content: t("Click here to create a new contract.") ,
    },
    {
      selector: ".step-2",
      content:t("This is your contract list.",) 
    },
    {
      selector: ".step-3",
      content: t( "Here you can fill project details")
    },
    
  ];
  return (
    <React.Fragment>
      <TourProvider styles={{
    popover: (base) => ({
      ...base,
      backgroundColor: "white",
      color: "black",
      borderRadius: "10px",
      padding: "15px",
      paddingTop:50,
      marginLeft:10
    }),
    arrow: (base) => ({
      ...base,
      color: "black",
    }),
    close: (base) => ({
      ...base,
      color: "red",          // Change icon color
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow
      "&:hover": {
        backgroundColor: "darkred",
      },
    }),
  }} steps={steps} >
      <Route />
      </TourProvider>
    </React.Fragment>
  );
};

export default App;
