import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  Card,
  ButtonGroup,
  Table,
  Dropdown,
  InputGroup,
  Spinner,
  Modal,
  Alert,
} from "react-bootstrap";
import Axios, { AxiosResponse } from "axios";
import { getAuthHeader } from "../../../../helpers/jwt-token-access/auth-token-header";
import {
  searchContractor,
  createSubContractor,
  getSingleProjectById,
  getSingleSubcontractor,
  getSubcontractors,
  createContSubcontractors,
} from "../../../../slices/thunk";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import Contract from "../contracts";
import SubContractorContract from "../AddContract/subcontractor";
import { PatternFormat } from "react-number-format";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import SignatureCanvas from "react-signature-canvas";
import { clearSingleSubcontractor } from "../../../../slices/subcontractors/reducer";
import apiUrl from "slices/apiEndpoint";
import { useTranslation } from "react-i18next";

interface ProjectDetailsProps {
  projectDetails: any;
  disable: any;
}
interface JwtPayload {
  userType: string;
}
const SubContractors: React.FC<ProjectDetailsProps> = ({
  projectDetails,
  disable,
}) => {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const contractors = useSelector(
    (state: any) => state.Proposal.searchContractors
  );
  const { t } = useTranslation(); // ✅ React knows to update


  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  }

  const formatTimestamp = (timestamp: any) => {
    const date = new Date(timestamp); // Assuming timestamp is already in milliseconds
    const options: any = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const project = useSelector((state: any) => state.Contract.project);

  const singleSubContractor = useSelector(
    (state: any) => state.Subcontractor.singleSubcontractor
  );

  const apiError = useSelector((state: any) => state.Subcontractor.error);

  const [subCont, setSubContData] = useState<any>({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [subContId, setSubContId] = useState<string>("");
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [modal_contract, setmodal_contract] = useState(false);
  const [modal_sign_subcontract, setModal_sign_subcontract] = useState(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [newContractors, setNewContractors] = useState<any>([]);
  const [subMilestones, setSubMilestones] = useState<any>(null);
  const [subContractId, setSubContractId] = useState<any>(null);
  const [isContractDone, setContractDone] = useState<any>(false);
  const [addagent, setAddagent] = useState<boolean>(false);
  const [selectfeils, setSelectfeils] = useState<any>([]);
  const [agentGrid, setAgentGrid] = useState<any>(null);
  const [editagent, setEditagent] = useState<boolean>(false);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);
  const [subNotification, setSubNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);

  //add agent modal
  const handleShowAgent = () => setAddagent(true);
  const handleCloseAgent = () => {
    setAddagent(false);
    setSelectfeils(null);
  };

  const formik: any = useFormik({
    enableReinitialize: true, // Allow reinitializing form values
    initialValues: {
      id: agentGrid?.po_id || "",
      firstName: agentGrid?.user_first_name || "",
      lastName: agentGrid?.user_last_name || "",
      email: agentGrid?.po_user_email || "",
      contact: agentGrid?.po_user_phone || "",
      status: agentGrid?.sc_is_active || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your Subcontractor Name"),
      lastName: Yup.string().required(
        "Please Enter Your Subcontractor Last Name"
      ),
      email: Yup.string()
        .email("Invalid email format")
        .matches(/^(?!.*@[^,]*,)/, "Invalid email format")
        .required("Please Enter Your Subcontractor Email"),
      contact: Yup.string().required("Please Enter Your Subcontractor Contact"),
      status: Yup.string().required("Please Enter Your Status"),
    }),
    onSubmit: async (values: any) => {
      try {
        setLoading(true);

        // Dispatch the action and check the response
        const response: any = await dispatch(createContSubcontractors(values));
        console.log(response, "response");
        if (response.error) {
          // Handle error from the thunk
          setNotification({
            status: true,
            message: response.error,
            variant: "danger",
          });
        } else if (response.data) {
          // Handle success
          setNotification({
            status: true,
            message: "Subcontractor created successfully!",
            variant: "success",
          });

          // Refresh subcontractors list
          const subcontractorsResponse: any =
            await dispatch(getSubcontractors());
          if (
            subcontractorsResponse.payload &&
            subcontractorsResponse.payload.result
          ) {
            setSubcontractor(subcontractorsResponse.payload.result);
          }

          // Reset form and close modal
          formik.resetForm();
          handleCloseAgent();
        }
      } catch (error: any) {
        // Handle unexpected errors
        setNotification({
          status: true,
          message: error.message || "An unexpected error occurred.",
          variant: "danger",
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const clearSignature = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
    }
  };
  const handleContractSubmition = (val: any) => {
    if (val) {
      setmodal_contract(false);
      setNotification({
        status: true,
        message: "Contract Created Successfully",
        variant: "success",
      });
    }
  };
  const [subcontractor, setSubcontractor] = useState<any>([]);
  const [formData, setFormData] = useState({
    subContractorId: "",
    subContractorName: "",
    subContractorPhone: "",
    subContractorEmail: "",
    subContractorTotalCost: "",
    tradesmanInspectionRequired: false,
  });
  const [milestones, setMilestones] = useState([
    {
      milestoneName: "",
      isTownInspected: false,
    },
    {
      milestoneName: "",
      isTownInspected: false,
    },
    {
      milestoneName: "",
      isTownInspected: false,
    },
  ]);
  const [projectId, setProjectId] = useState<any>("");
  function searchContractors(e: any) {
    dispatch(searchContractor(e));
  }
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleMilestoneInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, type, checked } = e.target;
    const updatedMilestones = [...milestones];
    updatedMilestones[index] = {
      ...updatedMilestones[index],
      [name]: type === "checkbox" ? checked : value,
    };
    setMilestones(updatedMilestones);
  };

  //
  useEffect(() => {
    if (notification && notification.status) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      // Cleanup the timeout if the component unmounts or notification changes
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleAlertClick = () => {
    setNotification(null);
  };
  //

  useEffect(() => {
    dispatch(getSubcontractors()).then((resp: any) => {
      if (resp && resp.result.length > 0) {
        setSubcontractor(resp.result);
      }
    });
  }, [dispatch]);

  const options = subcontractor.map((item: any) => ({
    value: item.id,
    label: `${item.user_first_name} ${item.user_last_name}`,
  }));

  const handleSelectedValue = async (e: any) => {
    setSelectedOption(e.value);
    setLoading(true);
    try {
      await dispatch(getSingleSubcontractor(e.value));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching homeowner data:", error);
    }
  };
  useEffect(() => {
    if (contractors.length) {
      const newData = contractors.map((item: any) => {
        return { label: item.user_email, value: item.id };
      });
      setNewContractors(newData);
    }
  }, [contractors]);

  useEffect(() => {
    if (singleSubContractor) {
      setFormData((prevState) => ({
        ...prevState,
        subContractorId: singleSubContractor.id,
        subContractorName: singleSubContractor.user_first_name,
        subContractorEmail: singleSubContractor.user_email,
        subContractorPhone: singleSubContractor.user_phone,
      }));
    }
  }, [singleSubContractor]);

  useEffect(() => {
    // Function to handle the click event
    const handleClickOutside = (event: MouseEvent) => {
      // Get the modal elements
      const backdropElement = document.getElementById("staticBackdrop");

      // Check if the click is outside the modal
      if (backdropElement && !backdropElement.contains(event.target as Node)) {
        // Close the modals if they are open
        if (modal_contract) {
          setmodal_contract(false);
        }
        if (modal_backdrop) {
          setmodal_backdrop(false);
        }
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal_contract, modal_backdrop]);

  function tog_backdrop(milestones: any, totalCost: any) {
    if (milestones !== null) {
      const transformedMilestones = milestones
        .map((item: any) => ({
          psmId: item.psm_id,
          milestoneName: item.psm_name,
          isTownInspected: item.psm_is_town_inspection === "0" ? false : true,
        }))
        .reverse();
      const firstMilestone = milestones[0];
      const transformedSubcontractor: any = {
        subContractorId: firstMilestone.psm_sub_contractor_id,
        subContractorTotalCost: totalCost,
        tradesmanInspectionRequired:
          firstMilestone.psm_tradesmen_inspection == 0 ? false : true,
      };

      setMilestones(transformedMilestones);
      setFormData(transformedSubcontractor);
    }
    setmodal_backdrop(!modal_backdrop);
  }
  function tog_contract(id: any) {
    setSubContId(id);
    setmodal_contract(!modal_contract);
  }

  useEffect(() => {
    if (!location.search) {
      console.log("No search parameters found");
      return;
    }

    // Remove the initial "?" from location.search and split by the first "?"
    const queryString = location.search.substring(1);
    const [mainQueryString, nestedQueryString] = queryString.split("?");
    // Parse the main query parameters
    const mainParams: any = new URLSearchParams(mainQueryString);

    // Check if there's a nested query string to parse
    let nestedParams: any = null;
    if (nestedQueryString) {
      nestedParams = new URLSearchParams(nestedQueryString);
    }
    const id = nestedParams ? nestedParams.get("id") : null;
    setProjectId(id);
  }, [location.search]);

  const createSubcontractor = () => {
    setLoading(true);
    let subContractor = null;

    subContractor = {
      projectId: projectId,
      userId: 1,
      subcontractors: [
        {
          totalCost: formData.subContractorTotalCost,
          subcontractorId: formData.subContractorId,
          isTradesmenInspection: formData.tradesmanInspectionRequired,
          subContractorMilestones: milestones,
        },
      ],
    };

    dispatch(createSubContractor(subContractor)).finally(() => {
      dispatch(clearSingleSubcontractor());
      dispatch(getSingleProjectById(project.p_id))
        .then(() => {
          if (project !== "") {
            setNotification({
              status: true,
              message: "Subcontractor Created Successfully",
              variant: "success",
            });
            setmodal_backdrop(false);
            setLoading(false);
          }
        })
        .catch((error: any) => {
          // Handle any errors that occurred during the getSingleProjectById dispatch
          console.error("Error fetching project:", error);
          dispatch(getSingleProjectById(project.p_id));
          setNotification({
            status: true,
            message: "Contract Created Successfully",
            variant: "success",
          });
          setLoading(false);
        });
    });
  };

  const submitSignature = async () => {
    const headers = getAuthHeader();
    if (!headers) {
      return;
    }
    if (sigCanvasRef.current && !sigCanvasRef.current.isEmpty()) {
      setLoading(true); // Set loading to true when submitting signature
      const signatureDataURL = sigCanvasRef.current.toDataURL();
      const signatureBlob = await fetch(signatureDataURL).then((res) =>
        res.blob()
      );
      const formData = new FormData();
      formData.append("file", signatureBlob, "signature.png");

      try {
        const response = await axios.post(
          `${apiUrl}/contract/sign-subcontract-contract/${decoded.userType}/${project.p_id}/${subContractId}`,
          formData,
          {
            headers: {
              ...headers,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response) {
          setModal_sign_subcontract(false);
          dispatch(getSingleProjectById(project.p_id)).finally(() =>
            setLoading(false)
          );
          if (project !== "") {
            setLoading(true);
            dispatch(getSingleProjectById(project.p_id)).finally(() =>
              setLoading(false)
            );
          }
          setNotification({
            status: true,
            message: "Subcontractor Signed Successfully",
            variant: "success",
          });
        } else {
          console.error("Failed to upload signature");
        }
      } catch (error) {
        console.error("Error uploading signature:", error);
      } finally {
        setLoading(false); // Set loading to false after submission completes
      }
    } else {
      alert("Please provide a signature first.");
    }
  };

  function tog_sign_subcontract(subContractId: any) {
    setSubContractId(subContractId);
    setModal_sign_subcontract(!modal_sign_subcontract);
  }
  useEffect(() => {
    // Function to handle the click event
    const handleClickOutside = (event: MouseEvent) => {
      // Get the modal elements
      const backdropElement = document.getElementById("staticBackdrop");

      // Check if the click is outside the modal
      if (backdropElement && !backdropElement.contains(event.target as Node)) {
        // Close the modals if they are open
        if (modal_sign_subcontract) {
          setModal_sign_subcontract(false);
        }
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal_sign_subcontract]);

  {
    notification && notification.status && (
      <div
        style={{
          position: "absolute",
          top: "5%",
          right: "24px",
          zIndex: 99999,
        }}
      >
        <Alert variant={notification.variant} onClick={handleAlertClick}>
          {notification.message}
        </Alert>
      </div>
    );
  }

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading && (
        <div className="custom-overlay show">
          <Spinner animation="border" role="status" className="custom-spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
 <Card>
  <Card.Body>
    <Row className="g-3 align-items-center mb-3">
      <h5 className="card-title mb-3">{t("Subcontractor details")}</h5>
      <Row>
        <Col>
          <p>{t("Search for a subcontractor in our database using the primary email address associated with their Tradesmen account registration. If the subcontractor is not found in the database, click on \"Add Subcontractor\" to add them to the system, and then search again using the same email address.")}</p>
        </Col>
        <Col className="col-auto text-end">
          <Button variant="secondary" onClick={handleShowAgent}>
            <i className="bi bi-person-plus align-baseline me-1"></i> {t("Add Subcontractor")}
          </Button>
        </Col>
      </Row>

      {decoded.userType === "subcontractor" || decoded.userType === "homeowner" ? (
        ""
      ) : (
        <Col lg={12}>
          <div className="mb-3">
            <Form.Label htmlFor="subcontractorId">
              {t("Select Subcontractor")}
              <span className="text-danger">*</span>
            </Form.Label>
            <Select
              id="subcontractorId"
              name="subcontractorId"
              value={selectedOption}
              onChange={handleSelectedValue}
              options={options}
              placeholder={t("Select Property Owner")}
            />
          </div>
        </Col>
      )}
      <Col lg={12}>
        <div className="table-responsive">
          <Table className="table-striped table-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th scope="col">{t("ID")}</th>
                <th scope="col">{t("Name")}</th>
                <th scope="col">{t("Phone Number")}</th>
                <th scope="col">{t("Email")}</th>
                <th scope="col">{t("Status")}</th>
                <th scope="col">{t("Date")}</th>
                <th scope="col">{t("Milestones")}</th>
                <th scope="col">{t("Contract")}</th>
                <th scope="col">{t("Create Contract")}</th>
                <th scope="col">{t("Sign")}</th>
              </tr>
            </thead>
            <tbody>
              {singleSubContractor && (
                <tr key={singleSubContractor.id}>
                  <td className="fw-medium">{singleSubContractor.id}</td>
                  <td>{singleSubContractor.user_first_name}</td>
                  <td>{singleSubContractor.user_phone}</td>
                  <td>{singleSubContractor.user_email}</td>
                  <td>
                    <span
                      className={
                        singleSubContractor.sc_is_active === "Active"
                          ? "badge bg-success"
                          : "badge bg-danger"
                      }
                    >
                      {singleSubContractor.sc_is_active}
                    </span>
                  </td>
                  <td>
                    {singleSubContractor.sc_created_at
                      ? formatTimestamp(singleSubContractor.sc_created_at)
                      : "N/A"}
                  </td>
                  <td>
                    <i className="ri-download-2-line fs-base lh-1 align-middle"></i>
                  </td>
                  <td>
                    <Button
                      className="primary"
                      onClick={() => tog_backdrop(null, null)}
                      disabled={disable}
                    >
                      {t("Add Milestones")}
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>

            <tbody>
              {project &&
                project.subcontractors.length > 0 &&
                project.subcontractors.map((item: any) =>
                  (decoded.userType === "subcontractor" &&
                    decoded.userId == item.ps_subcontractor_id) ||
                  decoded.userType !== "subcontractor" ? (
                    item.ps_id !== null ? (
                      <tr key={item.ps_id}>
                        <td className="fw-medium">{item.ps_id}</td>
                        <td>
                          {item.user_first_name} {item.user_last_name}
                        </td>
                        <td>{item.user_phone}</td>
                        <td>{item.user_email}</td>
                        <td>
                          <span className="badge bg-danger">{t("Pending")}</span>
                        </td>
                        <td>
                          {new Date(item.ps_created_at).toLocaleDateString()}
                        </td>
                        <td>
                          <Button
                            className="primary"
                            disabled={decoded.userType === "homeowner"}
                            onClick={() =>
                              tog_backdrop(item.milestones, item.ps_total_cost)
                            }
                          >
                            {t("Milestones")}
                          </Button>
                        </td>
                        <td>
                          {decoded.userType === "homeowner"
                            ? ""
                            : item.contractDetails.length &&
                              item.contractDetails[0].sc_contract_url != null ? (
                              <a
                                target="_blank"
                                href={item.contractDetails[0].sc_contract_url}
                              >
                                <i className="ri-download-2-line fs-base lh-1 align-middle"></i>
                              </a>
                            ) : (
                              "N/A"
                            )}
                        </td>
                        <td>
                          {item.contractDetails.length && item.contractDetails ? (
                            <Button
                              type="button"
                              variant="danger"
                              disabled={decoded.userType === "homeowner" || decoded.userType === "subcontractor"}
                              onClick={() => tog_contract(item.ps_subcontractor_id)}
                            >
                              {t("Update Contract")}
                            </Button>
                          ) : (
                            <Button
                              type="button"
                              variant="primary"
                              disabled={decoded.userType === "homeowner" || decoded.userType === "subcontractor"}
                              onClick={() => tog_contract(item.ps_subcontractor_id)}
                            >
                              {t("Create Contract")}
                            </Button>
                          )}
                        </td>
                        <td>
                          {item.contractDetails.length && item.contractDetails ? (
                            <Button
                              type="button"
                              variant="danger"
                              disabled={decoded.userType === "homeowner"}
                              onClick={() =>
                                tog_sign_subcontract(item.contractDetails[0].sc_id)
                              }
                            >
                              {t("Sign Contract")}
                            </Button>
                          ) : (
                            <Button type="button" variant="danger" disabled>
                              {t("Sign Contract")}
                            </Button>
                          )}
                        </td>
                      </tr>
                    ) : null
                  ) : null
                )}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  </Card.Body>
</Card>


      {/*Sign Contract Modal */}

      <Modal
  size="sm"
  show={modal_sign_subcontract}
  onHide={() => {
    tog_sign_subcontract(null);
  }}
  backdrop={"static"}
  id="staticBackdrop"
  centered
  data-bs-focus="false"
>
  <Modal.Header
    className="modal-title fw-bold"
    id="staticBackdropLabel"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    {t("Sign Contract")}
  </Modal.Header>
  <Modal.Body
    className="p-5"
    style={{ background: "rgb(249, 250, 251)" }}
  >
    <SignatureCanvas
      ref={sigCanvasRef}
      penColor="black"
      canvasProps={{
        width: 200,
        height: 200,
        className: "sigCanvas",
        style: { backgroundColor: "white" },
      }}
    />
    <div className="d-flex mt-2 justify-content-between">
      <Button variant="primary" onClick={clearSignature}>
        {t("Clear")}
      </Button>
      <Button variant="success" onClick={submitSignature}>
        {t("Submit")}
      </Button>
    </div>
  </Modal.Body>
</Modal>


<Modal
  size="lg"
  show={modal_backdrop}
  onHide={() => {
    tog_backdrop(null, null);
  }}
  backdrop={"static"}
  id="staticBackdrop"
  centered
>
  <Modal.Header className="modal-title fw-bold" id="staticBackdropLabel">
    {t("Milestones")}
  </Modal.Header>
  <Modal.Body className="p-5">
    <Row className="g-3 mb-3">
      <Col lg={6}>
        <div>
          <Form.Label htmlFor="projectName">
            {t("Total Subcontractor cost (if known)")}
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            id="subContractorTotalCost"
            name="subContractorTotalCost"
            placeholder={t("Enter Cost")}
            value={formData.subContractorTotalCost}
            onChange={handleInputChange}
            required
          />
        </div>
      </Col>
      <Col lg={6}>
        <div>
          <Form.Label htmlFor="tradesmanInspectionRequired">
            {t("Tradesman inspection required")}
            <span className="text-danger">*</span>
          </Form.Label>
          <div className="form-check form-switch mb-3">
            <Form.Check
              type="checkbox"
              id="tradesmanInspectionRequired"
              name="tradesmanInspectionRequired"
              onChange={handleInputChange}
              checked={formData.tradesmanInspectionRequired}
            />
          </div>
        </div>
      </Col>
    </Row>
    {milestones.map((item, index) => (
      <Row className="g-3 align-items-center mb-3" key={index}>
        <Col lg={6}>
          <div>
            <Form.Label htmlFor={`milestoneName${index}`}>
              {t("Name of Milestone")} {index + 1}
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              id={`milestoneName${index}`}
              name="milestoneName"
              placeholder={t("Milestone Name")}
              value={item.milestoneName}
              onChange={(e: any) => handleMilestoneInputChange(e, index)}
              required
            />
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <Form.Label htmlFor={`isTownInspected${index}`}>
              {t("Town inspection waived")}
              <span className="text-danger">*</span>
            </Form.Label>
            <div className="form-check form-switch mb-3">
              <Form.Check
                type="checkbox"
                role="switch"
                id={`isTownInspected${index}`}
                name="isTownInspected"
                onChange={(e: any) =>
                  handleMilestoneInputChange(e, index)
                }
                checked={item.isTownInspected}
              />
            </div>
          </div>
        </Col>
      </Row>
    ))}
    <Button
      variant="success"
      disabled={decoded.userType === "subcontractor" ? true : false}
      onClick={createSubcontractor}
    >
      {t("Submit")}
    </Button>
  </Modal.Body>
</Modal>

      {/*Contract Modal */}

      <Modal
        size="xl"
        show={modal_contract}
        onHide={() => {
          tog_contract("");
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
        data-bs-focus="false"
      >
        <Modal.Header
          className="modal-title fw-bold"
          id="staticBackdropLabel"
          style={{ background: "rgb(249, 250, 251)" }}
        >
         {t("Contract")} 
        </Modal.Header>
        <Modal.Body className="p-5">
          <SubContractorContract
            subContractorId={subContId}
            handleSubmition={handleContractSubmition}
          />
        </Modal.Body>
      </Modal>
      {/* Notification Alert */}

      {/*Add Subcontractor homeowner*/}
      <Modal show={addagent} onHide={handleCloseAgent}>
  <Modal.Header closeButton>
    <Modal.Title as="h5" id="addAgentModalLabel">
      {editagent ? t("Edit Property Owner") : t("Add Property Owner")}
    </Modal.Title>
  </Modal.Header>
  <Form className="tablelist-form" autoComplete="off" onSubmit={formik.handleSubmit}>
    <Modal.Body>
      <div className="mb-3">
        <Form.Label htmlFor="firstName">
          {t("Subcontractor First Name")} <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          id="firstName"
          name="firstName"
          placeholder={t("Enter Subcontractor First Name")}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.firstName}
        />
        {formik.errors.firstName && formik.touched.firstName && (
          <Form.Control.Feedback type="invalid">
            {formik.errors.firstName}
          </Form.Control.Feedback>
        )}
      </div>
      <div className="mb-3">
        <Form.Label htmlFor="lastName">
          {t("Subcontractor Last Name")} <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          id="lastName"
          name="lastName"
          placeholder={t("Enter Subcontractor Last Name")}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.lastName}
        />
        {formik.errors.lastName && formik.touched.lastName && (
          <Form.Control.Feedback type="invalid">
            {formik.errors.lastName}
          </Form.Control.Feedback>
        )}
      </div>
      <div className="mb-3">
        <Form.Label htmlFor="email">
          {t("Email")} <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          id="email"
          name="email"
          placeholder={t("Enter email")}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.email}
        />
        {formik.errors.email && formik.touched.email && (
          <Form.Control.Feedback type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        )}
      </div>
      <div className="mb-3">
        <Form.Label htmlFor="contact">
          {t("Contact Number")} <span className="text-danger">*</span>
        </Form.Label>
        <PatternFormat
          className="form-control"
          displayType="input"
          id="contact"
          placeholder={t("Enter contact no")}
          name="contact"
          format="##########"
          value={formik.values.contact}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.errors.contact && formik.touched.contact && (
          <Form.Control.Feedback type="invalid" className="d-block">
            {formik.errors.contact}
          </Form.Control.Feedback>
        )}
      </div>
      <div className="mb-3">
        <Form.Label htmlFor="status-type-input">
          {t("Status")} <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          className="form-control"
          id="status-type-input"
          name="status"
          value={formik.values.status}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.status}
        >
          <option value="">{t("Select Status")}</option>
          <option value="Active">{t("Active")}</option>
          <option value="Inactive">{t("Inactive")}</option>
        </Form.Select>
        {formik.errors.status && formik.touched.status && (
          <Form.Control.Feedback type="invalid">
            {formik.errors.status}
          </Form.Control.Feedback>
        )}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" className="btn btn-ghost-danger" onClick={handleCloseAgent}>
        <i className="bi bi-x-lg align-baseline me-1"></i> {t("Close")}
      </Button>
      <Button type="submit" variant="primary" id="add-btn">
        {editagent ? t("Edit") : t("Add")}
      </Button>
    </Modal.Footer>
  </Form>
</Modal>

    </React.Fragment>
  );
};

export default SubContractors;
