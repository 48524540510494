import React, { useEffect, useState } from "react";
import Breadcrumb from "Common/BreadCrumb";
import { Col, Container, Row, Spinner, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  acceptContractTerms,
  getSubscribedUser,
  handleStripePackage,
  cancelSubscription,
} from "../../slices/thunk";
import Tutorial from "pages/Contracts/CreateProject/Tutorial";
import OfferSlider from "./component/offer-slider";
import SlidderBanner from "../../assets/images/tutorial/slider-banner.png";
import { useTranslation } from "react-i18next";


interface CreateProjectProps {}
interface JwtPayload {
  userType: string;
}

const Marketing: React.FC<CreateProjectProps> = () => {
  const dispatch: any = useDispatch();
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error("Token is null");
  }
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation(); // ✅ React knows to update


  useEffect(() => {
    document.title = "Tradesmen Admin";
  }, []);

  const [activeArrowTab, setactiveArrowTab] = useState(1);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const handleCreateAndSetTab = () => {
    setactiveArrowTab(2); // Set to Milestones or any other tab index
  };
  // useEffect(() => {
  //   dispatch(getContractsById())
  // },[])

  useEffect(() => {
    setLoading(true);
    dispatch(getSubscribedUser()).then((resp: any) => {
      if (resp && resp.subscription.cpp_is_active === "ACTIVE") {
        setTermsAccepted(true);
      } else {
        setTermsAccepted(false);
      }
      setLoading(false);
    });
  }, [dispatch]);

  const userSubscribed = useSelector(
    (state: any) => state.UserProfile.subscribedData
  );
  const handleSubmitStatus = () => {
    setTermsAccepted(true);
  };
  const banners = [
    { banner: SlidderBanner, hurryUp: true, alt: "Offer Banner" },
  ];
  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={"Market & Website Development"}
          pageTitle={"Marketing & Website Development"}
        />
  
        <Row>
          <Col xl={12}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "200px" }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">{t("Loading...")}</span>
                </Spinner>
              </div>
            ) : (
              <OfferSlider banners={banners} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
  
  );
};

export default Marketing;
