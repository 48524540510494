import React, { useState, useEffect } from "react";
import { Card, Col, Button, Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropImage";
import { useTranslation } from "react-i18next";

interface PersonalDataProps {
  userData: any;
  data: any;
  onFileChange: (type: string, filePath: string) => void;
  onSocialChange: (social: string) => void;
  profileCompleted: any;
  createUserData: (values: any) => void;
  isLoading: any;
  successSoc: any;
}

const Right: React.FC<PersonalDataProps> = ({
  userData,
  data,
  onFileChange,
  onSocialChange,
  profileCompleted,
  createUserData,
  isLoading,
  successSoc,
}) => {
  const initialSocialData =
    data && data.ua_social_url
      ? JSON.parse(data.ua_social_url)
      : {
          linkedIn: "",
          fb: "",
          instagram: "",
        };
        const { t } = useTranslation(); // ✅ React knows to update


  const [coverImagePath, setCoverImagePath] = useState<string>("");
  const [profileImagePath, setProfileImagePath] = useState<string>("");
  const [inputValues, setInputValues] = useState(initialSocialData);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropFor, setCropFor] = useState<"profile" | "cover">("profile");
  const [imageSrc, setImageSrc] = useState<string>("");

  useEffect(() => {
    setInputValues(initialSocialData);
  }, [data]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const updatedValues = {
      ...inputValues,
      [fieldName]: event.target.value,
    };
    setInputValues(updatedValues);
    onSocialChange(JSON.stringify(updatedValues));
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "profile" | "cover"
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
        setCropFor(type);
        setCropModalOpen(true);
      };
    }
  };

  const handleCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropSave = async () => {
    const uniqueTimestamp = new Date().getTime();
    try {
      const croppedBlob: any = await getCroppedImg(imageSrc, croppedAreaPixels);
      console.log(croppedBlob, "croppedBlob");
      const file: any = new File(
        [croppedBlob],
        `${cropFor}-${uniqueTimestamp}-image.jpg`,
        {
          type: "image/jpeg",
        }
      );

      // Update state with cropped image immediately for both preview and final image
      if (cropFor === "profile") {
        const newProfileImageUrl = URL.createObjectURL(file);
        console.log(newProfileImageUrl, "newProfileImageUrl");
        setProfileImagePath(newProfileImageUrl); // Update the preview immediately
        onFileChange("profile", file); // Upload the file via callback
      } else {
        const newCoverImageUrl = URL.createObjectURL(file);
        setCoverImagePath(newCoverImageUrl); // Update the preview immediately
        onFileChange("cover", file); // Upload the file via callback
      }

      setCropModalOpen(false);
    } catch (error) {
      console.error("Crop failed:", error);
    }
  };

  return (
    <>
      <Modal
        show={cropModalOpen}
        onHide={() => setCropModalOpen(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="crop-container"
            style={{ position: "relative", width: "100%", height: "400px" }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={cropFor === "profile" ? 1 : 1024 / 500}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={handleCropComplete}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setCropModalOpen(false)}>
        {t("Cancel")}    
          </Button>
          <Button variant="primary" onClick={handleCropSave}>
         {t("Save Crop")}   
          </Button>
        </Modal.Footer>
      </Modal>

      <Col xxl={3}>
        <Card className="overflow-hidden">
          <div>
            <img
              src={data?.ua_cover_pic || coverImagePath}
              alt=""
              className="card-img-top profile-wid-img object-fit-cover"
              style={{ height: "200px" }}
            />
            <div>
              <input
                id="profile-foreground-img-file-input"
                type="file"
                className="profile-foreground-img-file-input d-none"
                onChange={(e) => handleImageChange(e, "cover")}
              />
              <label
                htmlFor="profile-foreground-img-file-input"
                className="profile-photo-edit btn btn-light btn-sm position-absolute end-0 top-0 m-3"
              >
                <i className="ri-image-edit-line align-bottom me-1"></i> Edit
              {t("Cover Images")}  
              </label>
            </div>
          </div>
          <Card.Body className="pt-0 mt-n5">
            <div className="text-center">
              <div className="profile-user position-relative d-inline-block mx-auto">
                <img
                  src={data?.ua_profile_pic || profileImagePath}
                  alt=""
                  className="avatar-lg rounded-circle object-fit-cover border-0 img-thumbnail user-profile-image"
                />
                <div className="avatar-xs p-0 rounded-circle profile-photo-edit position-absolute end-0 bottom-0">
                  <input
                    id="profile-img-file-input"
                    type="file"
                    className="profile-img-file-input d-none"
                    onChange={(e) => handleImageChange(e, "profile")}
                  />
                  <label
                    htmlFor="profile-img-file-input"
                    className="profile-photo-edit avatar-xs"
                  >
                    <span className="avatar-title rounded-circle bg-light text-body">
                      <i className="bi bi-camera"></i>
                    </span>
                  </label>
                </div>
              </div>
              <div className="mt-3">
                <h5>
                  {userData?.user_first_name} {userData?.user_last_name}
                  <i className="bi bi-patch-check-fill align-baseline text-info ms-1"></i>
                </h5>
                <p className="text-muted">{userData?.user_type}</p>
              </div>
            </div>
          </Card.Body>

          <Card.Body className="border-top">
            <div className="d-flex align-items-center mb-4 pb-2">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">{t("Complete Your Profile")}</h5>
              </div>
            </div>
            <div className="progress animated-progress custom-progress progress-label">
              <div
                className="progress-bar bg-primary"
                role="progressbar"
                style={{ width: `${profileCompleted}%` }}
                aria-valuenow={profileCompleted}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div className="label">{profileCompleted}%</div>
              </div>
            </div>
          </Card.Body>

          <Card.Body className="border-top">
            <div className="d-flex align-items-center mb-4">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">{t("Portfolio")} </h5>
              </div>
            </div>
            <div>
              <div className="mb-3 d-flex">
                <div className="avatar-xs d-block flex-shrink-0 me-3">
                  <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                    <i className="bi bi-facebook"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="websiteInput"
                  placeholder="www.example.com"
                  value={inputValues.fb}
                  onChange={(e) => handleChange(e, "fb")}
                />
              </div>
              <div className="mb-3 d-flex">
                <div className="avatar-xs d-block flex-shrink-0 me-3">
                  <span className="avatar-title rounded-circle bg-success-subtle text-success">
                    <i className="bi bi-linkedin"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="dribbleName"
                  placeholder="Username"
                  value={inputValues.linkedIn}
                  onChange={(e) => handleChange(e, "linkedIn")}
                />
              </div>
              <div className="d-flex">
                <div className="avatar-xs d-block flex-shrink-0 me-3">
                  <span className="avatar-title rounded-circle bg-danger-subtle text-danger">
                    <i className="bi bi-instagram"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="instagramName"
                  placeholder="Username"
                  value={inputValues.instagram}
                  onChange={(e) => handleChange(e, "instagram")}
                />
              </div>
            </div>
            <Button
              onClick={createUserData}
              variant="primary"
              className="mt-4 w-100"
              type="button"
            >
              {t("Submit")}
              
            </Button>
            {successSoc ? (
              <p style={{ color: "green" }}>
                <b>User Social Media url's added successfully!</b>
              </p>
            ) : null}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default Right;
