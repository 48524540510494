import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner, Alert } from "react-bootstrap";
import Dropzone from "react-dropzone";
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createProperty, getPropertyOwners } from "../../../slices/thunk";
import { useTranslation } from "react-i18next";


interface InformationPropertiesProps {
  editData: any;
  isEdit: Boolean;
}
const InformationProperties: React.FC<InformationPropertiesProps> = ({
  editData,
  isEdit,
}) => {
  const dispatch: any = useDispatch();
  const [selectFiles, setSelectFiles] = useState<any>(
    isEdit ? JSON.parse(editData.rep_images) : []
  );
  const { t } = useTranslation(); // ✅ React knows to update


  const [selectVideos, setSelectVideos] = useState<any>(
    isEdit ? JSON.parse(editData.rep_video) : []
  );
  const [loading, setLoading] = useState<any>(false);
  const [propertyOwner, setPropertyOwner] = useState<any>([]);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);
  const handleAcceptFiles = (files: any) => {
    const newImages = files?.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });
    setSelectFiles([...selectFiles, ...newImages]);
  };

  const handleAcceptVideos = (files: any) => {
    const newVideos = files?.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });
    setSelectVideos([...selectVideos, ...newVideos]);
  };

  useEffect(() => {
    dispatch(getPropertyOwners()).then((resp: any) => {
      if (resp.result.length > 0) {
        setPropertyOwner(resp.result);
      }
    });
  }, [dispatch]);

  const trimmedString = isEdit && editData.rep_additional_features.slice(1, -1); // Remove outer quotes
  const initialAdditionalFeatures = JSON.parse(trimmedString);

  const initialValues = {
    propertyTitle: isEdit ? editData.rep_title : "",
    propertyType: isEdit ? editData.rep_type : "",
    propertyDescription: isEdit ? editData.rep_description : "",
    bedroom: isEdit ? editData.rep_bedroom : "",
    bathroom: isEdit ? editData.rep_bathroom : "",
    halfBathroom: isEdit ? editData.rep_half_bathroom : "",
    parkingType: isEdit ? editData.rep_parking : "",
    waterType: isEdit ? editData.rep_watertype : [],
    taxesBy: isEdit ? editData.rep_taxes_by : "",
    isSeptic: isEdit ? editData.rep_isSeptic : "",
    isSever: isEdit ? editData.rep_isSever : "",
    sqft: isEdit ? editData.rep_sqft : "",
    price: isEdit ? editData.rep_price : "",
    streetAddress: isEdit ? editData.rep_street_address : "",
    state: isEdit ? editData.rep_state : "",
    country: isEdit ? editData.rep_country : "",
    zipcode: isEdit ? editData.rep_zip : "",
    additionalFeatures: [],
    propertyOwnerId: "",
    saleOrRent: isEdit ? editData.rep_sale_type : "",
    isActive: isEdit ? editData.rep_is_active : false,
    yearBuilt: isEdit ? editData.rep_year_built : "",
    hasGarage: isEdit ? editData.rep_is_garage : false,
    garageSize: isEdit ? editData.rep_garage_size : "",
    basement: isEdit ? editData.rep_is_basement : false,
    basementSize: isEdit ? editData.rep_basement_size : "",
    basementType: isEdit ? editData.rep_basement_type : "",
  };

  const validationSchema = Yup.object().shape({
    propertyTitle: Yup.string().required("Property title is required"),
    propertyType: Yup.string().required("Property type is required"),
    propertyDescription: Yup.string().required(
      "Property Description is required"
    ),
    bedroom: Yup.number().required("Number of bedrooms is required"),
    bathroom: Yup.number().required("Number of bathrooms is required"),
    halfBathroom: Yup.number().required("Number of Half Bathrooms is required"),
    taxesBy: Yup.string().required("Taxes is required"),
    parkingType: Yup.string().required("Parking Type is required"),
    isSeptic: Yup.string().required("Septic Type is required"),
    isSever: Yup.string().required("Sever Type is required"),
    waterType: Yup.array()
      .min(1, "Water Type is required")
      .required("Water Type is required"),
    sqft: Yup.number().required("SQFT is required"),
    streetAddress: Yup.string().required("Street address is required"),
    price: Yup.string().required("Property Price or Monthly Rent is Required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zipcode: Yup.string().required("Zipcode is required"),
    saleOrRent: Yup.string().required("Please select sale or rent"),
    additionalFeatures: Yup.array(Yup.string()),
    isActive: Yup.boolean().required("Property activation status is required"),
    propertyOwnerId: Yup.string().required(
      "Property activation status is required"
    ),
    basementSize: Yup.string().when("basement", {
      is: (basement: boolean) => basement === true,
      then: (schema) => schema.required("Basement size is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    basementType: Yup.string().when("basement", {
      is: (basement: boolean) => basement === true,
      then: (schema) => schema.required("Basement Type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    garageSize: Yup.string().when("hasGarage", {
      is: (hasGarage: boolean) => hasGarage === true,
      then: (schema) => schema.required("Garage Size is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const handleSubmit = (values: any, { resetForm }: any) => {
    setLoading(true);
    const formData = {
      ...values,
      propId: isEdit ? editData.rep_id : "",
      images: selectFiles,
      videos: selectVideos,
    };
    console.log(formData, "formData");
    dispatch(createProperty(formData)).finally(() => {
      setSelectFiles([]);
      setLoading(false);
      resetForm();
      setNotification({
        status: true,
        message: isEdit
          ? "Property Updated Successfully"
          : "Property Created Successfully",
        variant: "success",
      });
    });
  };
  const handleAlertClick = () => {
    setNotification(null);
  };
  return (
    <Col lg={12}>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Card>
                 <Card.Header>
  <h5 className="card-title mb-0">{t("Information Properties")}</h5>
</Card.Header>

          <Card.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <FormikForm>
                  {/* Dropzone for images */}
                  <div className="mb-3">
  <label className="form-label">
    {t("Property Images")}
    <span className="text-danger">*</span>
  </label>
  <Dropzone onDrop={(acceptedFiles: any) => handleAcceptFiles(acceptedFiles)}>
    {({ getRootProps }: any) => (
      <div
        className="dropzone course-dropzone border border-1 border-dashed text-center"
        style={{ minHeight: "80px" }}
      >
        <div className="dz-message needsclick" {...getRootProps()}>
          <div className="mb-3">
            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
          </div>
          <h4>{t("Drop files here or click to upload.")}</h4>
        </div>
      </div>
    )}
  </Dropzone>
  <ul className="list-unstyled mb-0" id="dropzone-preview">
    {selectFiles.map((file: any, index: number) => (
      <li className="mt-2 dz-image-preview" key={index}>
        <div className="border rounded">
          <div className="d-flex flex-wrap gap-2 p-2">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-2">
                <img
                  data-dz-thumbnail=""
                  className="img-fluid rounded d-block"
                  src={file.preview}
                />
              </div>
            </div>
            <div className="flex-shrink-0 ms-3">
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  const newImages = [...selectFiles];
                  newImages.splice(index, 1);
                  setSelectFiles(newImages);
                }}
              >
                {t("Delete")}
              </Button>
            </div>
          </div>
        </div>
      </li>
    ))}
  </ul>
</div>


                  {/* Dropzone for videos */}
                  <div className="mb-3">
  <label className="form-label">{t("Property Videos")}</label>
  <Dropzone
    onDrop={(acceptedFiles: any) => {
      handleAcceptVideos(acceptedFiles);
    }}
  >
    {({ getRootProps }: any) => (
      <div
        className="dropzone course-dropzone border border-1 border-dashed text-center"
        style={{ minHeight: "80px" }}
      >
        <div className="dz-message needsclick" {...getRootProps()}>
          <div className="mb-3">
            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
          </div>
          <h4>{t("Drop files here or click to upload.")}</h4>
        </div>
      </div>
    )}
  </Dropzone>
  <ul className="list-unstyled mb-0" id="dropzone-preview">
    {selectVideos.map((file: any, index: number) => (
      <li className="mt-2 dz-video-preview" key={index}>
        <div className="border rounded">
          <div className="d-flex flex-wrap gap-2 p-2">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-2">
                <video
                  data-dz-thumbnail=""
                  className="img-fluid rounded d-block"
                  src={file.preview}
                  controls
                />
              </div>
            </div>
            <div className="flex-shrink-0 ms-3">
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  const newVideos = [...selectVideos];
                  newVideos.splice(index, 1);
                  setSelectVideos(newVideos);
                }}
              >
                {t("Delete")}
              </Button>
            </div>
          </div>
        </div>
      </li>
    ))}
  </ul>
</div>


                  {/* Property details */}
                  <Row>
                  <Col lg={6}>
  <div className="mb-3">
    <Form.Label htmlFor="propertyTitle">
      {t("Property Title")}<span className="text-danger">*</span>
    </Form.Label>
    <Field
      type="text"
      id="propertyTitle"
      name="propertyTitle"
      placeholder={t("Enter property title")}
      className="form-control"
    />
    <ErrorMessage
      name="propertyTitle"
      component="div"
      className="error text-danger"
    />
  </div>
</Col>
<Col lg={6}>
  <div className="mb-3">
    <Form.Label htmlFor="propertyType">
      {t("Property Type")}<span className="text-danger">*</span>
    </Form.Label>
    <Field
      as="select"
      id="propertyType"
      name="propertyType"
      className="form-select"
    >
      <option value="">{t("Select Property Type")}</option>
      <option value="Villa">{t("Villa")}</option>
      <option value="Residency">{t("Residency")}</option>
      <option value="Apartment">{t("Apartment")}</option>
      <option value="Others">{t("Other")}</option>
    </Field>
    <ErrorMessage
      name="propertyType"
      component="div"
      className="error text-danger"
    />
  </div>
</Col>
<Col lg={12}>
  <div className="mb-3">
    <Form.Label htmlFor="propertyOwnerId">
      {t("Select Property Owner")}
      <span className="text-danger">*</span>
    </Form.Label>
    <Field
      as="select"
      id="propertyOwnerId"
      name="propertyOwnerId"
      className="form-select"
    >
      <option value="">{t("Select Property Owner")}</option>
      {propertyOwner.length > 0 &&
        propertyOwner.map((item: any) => (
          <option key={item.po_id} value={item.po_id}>
            {item.po_name}
          </option>
        ))}
    </Field>
    <ErrorMessage
      name="propertyOwnerId"
      component="div"
      className="error text-danger"
    />
  </div>
</Col>
<Col lg={12}>
  <div className="mb-3">
    <Form.Label htmlFor="propertyDescription">
      {t("Property Description")}
      <span className="text-danger">*</span>
    </Form.Label>
    <Field
      as="textarea"
      id="propertyDescription"
      name="propertyDescription"
      placeholder={t("Enter property Description")}
      className="form-control"
    />
    <ErrorMessage
      name="propertyDescription"
      component="div"
      className="error text-danger"
    />
  </div>
</Col>
<Col lg={6}>
  <div className="mb-3">
    <Form.Label htmlFor="bedroom">
      {t("Bedroom")}<span className="text-danger">*</span>
    </Form.Label>
    <Field
      type="number"
      id="bedroom"
      name="bedroom"
      placeholder={t("Enter bedroom count")}
      className="form-control"
    />
    <ErrorMessage
      name="bedroom"
      component="div"
      className="error text-danger"
    />
  </div>
</Col>
<Col lg={3}>
  <div className="mb-3">
    <Form.Label htmlFor="bathroom">
      {t("Full Bathroom")}<span className="text-danger">*</span>
    </Form.Label>
    <Field
      type="number"
      id="bathroom"
      name="bathroom"
      placeholder={t("Enter bathroom count")}
      className="form-control"
    />
    <ErrorMessage
      name="bathroom"
      component="div"
      className="error text-danger"
    />
  </div>
</Col>
<Col lg={3}>
  <div className="mb-3">
    <Form.Label htmlFor="halfBathroom">
      {t("Half Bathroom")}<span className="text-danger">*</span>
    </Form.Label>
    <Field
      type="number"
      id="halfBathroom"
      name="halfBathroom"
      placeholder={t("Enter half bathroom count")}
      className="form-control"
    />
    <ErrorMessage
      name="halfBathroom"
      component="div"
      className="error text-danger"
    />
  </div>
</Col>

<Col lg={6}>
  <div className="mb-3">
    <Form.Label>
      {t("Parking")}<span className="text-danger">*</span>
    </Form.Label>
    <Field as="select" id="parkingType" name="parkingType" className="form-select">
      <option value="">{t("Select Parking Type")}</option>
      <option value="garage parking">{t("Garage parking")}</option>
      <option value="on street parking">{t("On Street Parking")}</option>
      <option value="driveway parking">{t("Driveway Parking")}</option>
      <option value="under building parking">{t("Under Building Parking")}</option>
    </Field>
    <ErrorMessage name="parkingType" component="div" className="error text-danger" />
  </div>
</Col>

<Col lg={6}>
  <div className="mb-3">
    <Form.Label>
      {t("Water Type")}<span className="text-danger">*</span>
    </Form.Label>
    <div role="group" aria-labelledby="waterType" style={{ display: "flex", gap: "16px", alignItems: "center", flexWrap: "wrap" }}>
      <label style={{ display: "flex", alignItems: "center", gap: "8px", fontSize: "1rem" }}>
        <Field type="checkbox" name="waterType" id="waterType" value="public_water" />
        {t("Public water")}
      </label>
      <label style={{ display: "flex", alignItems: "center", gap: "8px", fontSize: "1rem" }}>
        <Field type="checkbox" name="waterType" id="waterType" value="well_water" />
        {t("Well water")}
      </label>
      <label style={{ display: "flex", alignItems: "center", gap: "8px", fontSize: "1rem" }}>
        <Field type="checkbox" name="waterType" id="waterType" value="rainwater_harvesting" />
        {t("Rainwater harvesting")}
      </label>
    </div>
    <ErrorMessage name="waterType" component="div" className="error text-danger" />
  </div>
</Col>

<Col lg={6}>
  <div className="mb-3">
    <Form.Label>
      {t("Sale or Rent")}<span className="text-danger">*</span>
    </Form.Label>
    <Field as="select" id="saleOrRent" name="saleOrRent" className="form-select">
      <option value="">{t("Select Sale Type")}</option>
      <option value="sale">{t("Sale")}</option>
      <option value="rent">{t("Rent")}</option>
    </Field>
    <ErrorMessage name="saleOrRent" component="div" className="error text-danger" />
  </div>
</Col>

<Col lg={6}>
  <div className="mb-3">
    <Form.Label htmlFor="priceOrRent">
      {t("Price / Monthly Rent")}<span className="text-danger">*</span>
    </Form.Label>
    <div className="input-group">
      <span className="input-group-text">$</span>
      <Field type="number" id="priceOrRent" name="price" placeholder={t("Enter price")} className="form-control" />
    </div>
    <ErrorMessage name="price" component="div" className="error text-danger" />
  </div>
</Col>

<Col lg={6}>
  <div className="mb-3">
    <Form.Label htmlFor="sqft">
      {t("SQFT")}<span className="text-danger">*</span>
    </Form.Label>
    <Field type="number" id="sqft" name="sqft" placeholder={t("Enter sqft area")} className="form-control" />
    <ErrorMessage name="sqft" component="div" className="error text-danger" />
  </div>
</Col>

<Col lg={6}>
  <div className="mb-3">
    <Form.Label htmlFor="taxesBy">
      {t("Taxes")}<span className="text-danger">*</span>
    </Form.Label>
    <div className="input-group">
      <Field as="select" id="taxesBy" name="taxesBy" className="form-select">
        <option value="">{t("Select Taxes Type")}</option>
        <option value="by realtor only">{t("By Realtor Only")}</option>
      </Field>
      <ErrorMessage name="taxesBy" component="div" className="error text-danger" />
    </div>
  </div>
</Col>

<Col lg={6}>
  <div className="mb-3">
    <Form.Label htmlFor="isSeptic">
      {t("Septic")}<span className="text-danger">*</span>
    </Form.Label>
    <div className="input-group">
      <Field as="select" id="isSeptic" name="isSeptic" className="form-select">
        <option value="">{t("Select Septic Type")}</option>
        <option value="yes">{t("Yes")}</option>
        <option value="no">{t("No")}</option>
      </Field>
      <ErrorMessage name="isSeptic" component="div" className="error text-danger" />
    </div>
  </div>
</Col>

<Col lg={6}>
  <div className="mb-3">
    <Form.Label htmlFor="isSever">
      {t("Sever")}
      <span className="text-danger">*</span>
    </Form.Label>
    <div className="input-group">
      <Field as="select" id="isSever" name="isSever" className="form-select">
        <option value="">{t("Select Sever Type")}</option>
        <option value="yes">{t("Yes")}</option>
        <option value="no">{t("No")}</option>
      </Field>
      <ErrorMessage name="isSever" component="div" className="error text-danger" />
    </div>
  </div>
</Col>
<Col lg={12}>
  <div className="mb-3">
    <Form.Label htmlFor="streetAddress">
      {t("Street Address")}<span className="text-danger">*</span>
    </Form.Label>
    <Field
      type="text"
      id="streetAddress"
      name="streetAddress"
      placeholder={t("Enter street address")}
      className="form-control"
    />
    <ErrorMessage name="streetAddress" component="div" className="error text-danger" />
  </div>
</Col>
<Col lg={4}>
  <div className="mb-3">
    <Form.Label htmlFor="state">
      {t("State")}<span className="text-danger">*</span>
    </Form.Label>
    <Field
      type="text"
      id="state"
      name="state"
      placeholder={t("Enter state")}
      className="form-control"
    />
    <ErrorMessage name="state" component="div" className="error text-danger" />
  </div>
</Col>
<Col lg={4}>
  <div className="mb-3">
    <Form.Label htmlFor="country">
      {t("Country")}<span className="text-danger">*</span>
    </Form.Label>
    <Field
      type="text"
      id="country"
      name="country"
      placeholder={t("Enter country")}
      className="form-control"
    />
    <ErrorMessage name="country" component="div" className="error text-danger" />
  </div>
</Col>
<Col lg={4}>
  <div className="mb-3">
    <Form.Label htmlFor="zipcode">
      {t("Zipcode")}<span className="text-danger">*</span>
    </Form.Label>
    <Field
      type="text"
      id="zipcode"
      name="zipcode"
      placeholder={t("Enter zipcode")}
      className="form-control"
    />
    <ErrorMessage name="zipcode" component="div" className="error text-danger" />
  </div>
</Col>
<Col lg={12}>
  <div className="mb-3">
    <Form.Label>{t("Additional Features")}</Form.Label>
    <FieldArray name="additionalFeatures">
      {({ push, remove }) => (
        <div>
          {values.additionalFeatures.map((_, index) => (
            <div key={index} className="input-group mb-3">
              <Field
                name={`additionalFeatures.${index}`}
                placeholder={t("Enter additional feature")}
                className="form-control"
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => remove(index)}
              >
                {t("Remove")}
              </button>
            </div>
          ))}
          <button type="button" className="btn btn-outline-primary" onClick={() => push("")}>
            {t("Add Additional Feature")}
          </button>
        </div>
      )}
    </FieldArray>
  </div>
</Col>
<Col lg={12}>
  <div className="mb-3">
    <Form.Label htmlFor="yearBuilt">{t("Year Built")}</Form.Label>
    <Field
      type="text"
      id="yearBuilt"
      name="yearBuilt"
      placeholder={t("Enter year built")}
      className="form-control"
    />
    <ErrorMessage name="yearBuilt" component="div" className="error text-danger" />
  </div>
</Col>

<Col lg={6}>
  <div className="mb-3 form-check">
    <Field type="checkbox" id="hasGarage" name="hasGarage" className="form-check-input" />
    <Form.Label htmlFor="hasGarage" className="form-check-label">
      {t("Has Garage")}
    </Form.Label>
    <ErrorMessage name="hasGarage" component="div" className="error text-danger" />
  </div>
</Col>
<Col lg={6}>
  {values.hasGarage && (
    <div className="mb-3">
      <Form.Label htmlFor="garageSize">
        {t("Garage Size(sq/ft)")} <span className="text-danger">*</span>
      </Form.Label>
      <Field type="number" id="garageSize" name="garageSize" placeholder={t("Enter garage size")} className="form-control" />
      <ErrorMessage name="garageSize" component="div" className="error text-danger" />
    </div>
  )}
</Col>
<Col lg={6}>
  <div className="mb-3 form-check">
    <Field type="checkbox" id="basement" name="basement" className="form-check-input" />
    <Form.Label htmlFor="basement" className="form-check-label">
      {t("Basement")}
    </Form.Label>
    <ErrorMessage name="basement" component="div" className="error text-danger" />
  </div>
</Col>
<Row>
  <Col lg={6}>
    {values.basement && (
      <div className="mb-3">
        <Form.Label htmlFor="basementSize">
          {t("Basment Size")} <span className="text-danger">*</span>
        </Form.Label>
        <Field type="text" id="basementSize" name="basementSize" placeholder={t("Enter Basement size")} className="form-control" />
        <ErrorMessage name="basementSize" component="div" className="error text-danger" />
      </div>
    )}
  </Col>
  <Col lg={6}>
    {values.basement && (
      <div className="mb-3">
        <Form.Label htmlFor="basementType">
          {t("Basment Type")} <span className="text-danger">*</span>
        </Form.Label>
        <div className="input-group">
          <Field as="select" id="basementType" name="basementType" className="form-select">
            <option value="">{t("Select Basement Type")}</option>
            <option value="yes">{t("Finished")}</option>
            <option value="no">{t("Unfinished")}</option>
          </Field>
          <ErrorMessage name="basementType" component="div" className="error text-danger" />
        </div>
      </div>
    )}
  </Col>
</Row>
<Col lg={12}>
  <div className="mb-3 form-check">
    <Field type="checkbox" id="isActive" name="isActive" className="form-check-input" />
    <Form.Label htmlFor="isActive" className="form-check-label">
      {t("Property is active")}
    </Form.Label>
    <ErrorMessage name="isActive" component="div" className="error text-danger" />
  </div>
</Col>
<Col lg={12}>
  <div className="hstack gap-2 justify-content-end">
    <Button variant="secondary" type="reset">
      <i className="bi bi-repeat align-baseline me-1"></i> {t("Reset")}
    </Button>
    <Button variant="primary" type="submit" className="btn btn-primary" disabled={isSubmitting}>
      <i className="bi bi-clipboard2-check align-baseline me-1"></i> {t("Save")}
    </Button>
  </div>
</Col>

                  </Row>
                </FormikForm>
              )}
            </Formik>
          </Card.Body>
        </Card>
      )}
    </Col>
  );
};

export default InformationProperties;
