// decodeJwt.ts
import { jwtDecode } from "jwt-decode";

export interface JwtPayload {
  userType: string;
  email:string
}

const decodeJwt = (): JwtPayload | null => {
  const token = localStorage.getItem('authUser');
  let decoded: JwtPayload | null = null;

  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error('Token is null');
  }

  return decoded;
};

export default decodeJwt;
