import React, { useState} from 'react'
import { Card, Col } from 'react-bootstrap'

import small7 from "assets/images/small/img-7.jpg";
import avatar1 from "assets/images/users/avatar-1.jpg";
import { Link } from 'react-router-dom';
import { t } from 'i18next';


interface PersonalDataProps{ 
  data: any
}
const AdminRight: React.FC<PersonalDataProps> = ({ data }) => {
  
  const [coverImagePath, setCoverImagePath] = useState<string>("");
  const [profileImagePath, setProfileImagePath] = useState<string>("");

  
let socialData = data && data.ua_social_url ? JSON.parse(data.ua_social_url) : "";


  return (
    <React.Fragment>
      <Col xxl={3}>
        <Card className=" overflow-hidden">
          <div>
            <img src={data != null && data.ua_cover_pic != null ? data.ua_cover_pic : coverImagePath || small7} alt="" className="card-img-top profile-wid-img object-fit-cover" style={{ height: "200px" }} />
            
          </div>
          <Card.Body className="pt-0 mt-n5">
            <div className="text-center">
              <div className="profile-user position-relative d-inline-block mx-auto">
                <img src={data != null && data.ua_profile_pic != null ? data.ua_profile_pic :  profileImagePath || 'https://res.cloudinary.com/dinwqfgid/image/upload/v1700236936/Max-R_Headshot_20_1_uewrjd.jpg'} alt="" className="avatar-lg rounded-circle object-fit-cover border-0 img-thumbnail user-profile-image" />
                
              </div>
              <div className="mt-3">
                <h5>{data && data.user_first_name} { data && data.user_last_name} <i className="bi bi-patch-check-fill align-baseline text-info ms-1"></i></h5>
                <p className="text-muted">{data && data.user_type}</p>
              </div>
            </div>
          </Card.Body>
          <Card.Body className="border-top">
            <div className="d-flex align-items-center mb-4 pb-2">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">{t("Complete Your Profile")}</h5>
              </div>
              {/* <div className="flex-shrink-0">
                <Link to="#" className="badge bg-light text-secondary"><i className="ri-edit-box-line align-bottom me-1"></i> Edit</Link>
              </div> */}
            </div>

            <div className="progress animated-progress custom-progress progress-label">
              <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${30}%` }}
                aria-valuenow={30} aria-valuemin={0} aria-valuemax={100}>
                <div className="label">30%</div>
              </div>

            </div>
          </Card.Body>
          <Card.Body className="border-top">
            <div className="d-flex align-items-center mb-4">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">Portfolio</h5>
              </div>
              {/* <div className="flex-shrink-0">
                <Link to="#" className="badge bg-info-subtle text-info fs-xs"><i className="ri-add-fill align-bottom me-1"></i> Add</Link>
              </div> */}
            </div>
           <div>
      <div className="mb-3 d-flex">
        <div className="avatar-xs d-block flex-shrink-0 me-3">
          <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
            <i className="bi bi-facebook"></i>
          </span>
        </div>
        <input 
          type="text" 
          className="form-control" 
                  id="websiteInput" 
                  disabled
          placeholder="www.example.com"
          value={socialData && socialData.fb != '' ? socialData.fb :""}
        />
      </div>
      <div className="mb-3 d-flex">
        <div className="avatar-xs d-block flex-shrink-0 me-3">
          <span className="avatar-title rounded-circle bg-success-subtle text-success">
            <i className="bi bi-linkedin"></i>
          </span>
        </div>
        <input 
          type="text" 
          className="form-control" 
                  id="dribbleName" 
                  disabled
          placeholder="Username"
          value={socialData && socialData.linkedIn != '' ? socialData.linkedIn : ""}
        />
      </div>
      <div className="d-flex">
        <div className="avatar-xs d-block flex-shrink-0 me-3">
          <span className="avatar-title rounded-circle bg-danger-subtle text-danger">
            <i className="bi bi-instagram"></i>
          </span>
        </div>
        <input 
          type="text" 
          className="form-control" 
                  id="instagramName" 
                  disabled
          placeholder="Username"
          value={socialData && socialData.instagram != '' ? socialData.instagram : ""}
        />
      </div>
    </div>
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment >
  )
}

export default AdminRight