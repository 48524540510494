import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner, Alert } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createRecentWork, getRecentWork } from "../../../../slices/thunk";
import { useTranslation } from "react-i18next";

interface AddRecentWorkProps {
  contractorId: any;
  handleSucces: (type: any) => void;
}

const AddRecentWork: React.FC<AddRecentWorkProps> = ({
  contractorId,
  handleSucces,
}) => {
  const dispatch: any = useDispatch();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<any>(false);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFiles([...selectedFiles, ...acceptedFiles]);
  };

  const handleRemove = (index: number) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const filePreviews = selectedFiles.map((file, index) => (
    <div key={index} className="d-flex align-items-center mb-2">
      <img
        src={URL.createObjectURL(file)} // Create a preview URL for the file
        alt={file.name}
        style={{ height: "50px", width: "auto", marginRight: "10px" }}
      />
      <span>{file.name}</span>
      <Button
        variant="danger"
        size="sm"
        className="ml-4"
        onClick={() => handleRemove(index)}
      >
        Remove
      </Button>
    </div>
  ));

  const formik = useFormik({
    initialValues: {
      recentWorkName: "",
      recentWorkDescription: "",
      recentWorkContId: contractorId,
      recentWorkvideo: null,
      startedAt: "",
      completedAt: "",
    },
    validationSchema: Yup.object({
      recentWorkName: Yup.string().required("Required"),
      recentWorkDescription: Yup.string().required("Required"),
      startedAt: Yup.string().required("Required"),
      completedAt: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const formData = {
        ...values,
        images: selectedFiles,
      };
      dispatch(createRecentWork(formData)).finally(() => {
        formik.resetForm();
        setSelectedFiles([]);
        setLoading(false);
        dispatch(getRecentWork());
        handleSucces(true);
        setNotification({
          status: true,
          message: "Recent Work Added Successfully",
          variant: "success",
        });
      });
    },
  });

  const handleAlertClick = () => {
    setNotification(null);
  };
const {t} = useTranslation()
  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {t(notification.message) }
          </Alert>
        </div>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t("Loading...")} </span>
          </Spinner>
        </div>
      ) : (
        <Col lg={12}>
          <Form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label className="form-label">
              {t("Recent Work Images")}  <span className="text-danger">*</span>
              </label>
              <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }: any) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                      {t("Drag 'n' drop some files here, or click to select files")}
                      
                    </p>
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="mt-4">
              <Form.Label>{t("Selected Files")} :</Form.Label>
              {filePreviews}
            </div>

            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="recentWorkName">
                 {t("Recent Work Title")}   <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="recentWorkName"
                    name="recentWorkName"
                    placeholder="Enter recent work title"
                    value={formik.values.recentWorkName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      !!formik.errors.recentWorkName &&
                      formik.touched.recentWorkName
                    }
                  />
                  {formik.errors.recentWorkName &&
                    formik.touched.recentWorkName && (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.recentWorkName}
                      </Form.Control.Feedback>
                    )}
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="recentWorkDescription">
                 {t("Recent Work Description")}   
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    id="recentWorkDescription"
                    name="recentWorkDescription"
                    placeholder="Enter recent work description"
                    value={formik.values.recentWorkDescription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      !!formik.errors.recentWorkDescription &&
                      formik.touched.recentWorkDescription
                    }
                  />
                  {formik.errors.recentWorkDescription &&
                    formik.touched.recentWorkDescription && (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.recentWorkDescription}
                      </Form.Control.Feedback>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Form.Label htmlFor="startedAt">
                {t("Started At")}    <span className="text-danger">*</span>
                  </Form.Label>
                  <Flatpickr
                    id="startedAt"
                    name="startedAt"
                    className="form-control"
                    options={{ dateFormat: "d M, Y" }}
                    placeholder="Select date"
                    value={formik.values.startedAt}
                    onChange={(date: Date[]) =>
                      formik.setFieldValue("startedAt", date[0])
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.startedAt && formik.touched.startedAt && (
                    <div className="text-danger">{formik.errors.startedAt}</div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Form.Label htmlFor="completedAt">
              {t("Completed At")}      <span className="text-danger">*</span>
                  </Form.Label>
                  <Flatpickr
                    id="completedAt"
                    name="completedAt"
                    className="form-control"
                    options={{ dateFormat: "d M, Y" }}
                    placeholder="Select date"
                    value={formik.values.completedAt}
                    onChange={(date: Date[]) =>
                      formik.setFieldValue("completedAt", date[0])
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.completedAt && formik.touched.completedAt && (
                    <div className="text-danger">
                      {formik.errors.completedAt}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
            {t("Submit")}  
            </Button>
          </Form>
        </Col>
      )}
    </React.Fragment>
  );
};

export default AddRecentWork;
